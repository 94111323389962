import axios from 'axios';
const checkEmailRegistered = async (email: object ,apiUrl:string) => {
  try {
    const response = await axios.post(
      `${apiUrl}getUser`,
      email,
      {
        method: 'POST',
        headers: {
          accept: 'application/json',
        },
        timeout:30000   
      }
    );
    const apiData = response.data;
    return apiData.userRecord;
  } catch (error) {
    return 'Error'
  }
};
export default checkEmailRegistered;