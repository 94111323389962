import { Stripe, loadStripe } from "@stripe/stripe-js";
import { environment } from "../../environments/environment";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      environment?.stripePublishableKey
    );
  }
  return stripePromise;
};
export default initializeStripe;
