import {createReducer} from '@reduxjs/toolkit';
import {AnyAction, Reducer} from 'redux';
import { IUserState } from '../types/stateTypes';

// Define the Question type
export interface IUserProfile {
  id: number;
  text: string;
}

interface Action {
  type: string;
  payload: any; // Specify the actual type if possible
}
const INITIAL_STATE: IUserState = {
  error: null,
  user: null,
};

const userProfileReducer: Reducer<IUserState, Action> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case 'SET_USERPROFILE':
      return {...state, user: action['payload']};
    default:
      return state;
  }
};

export default userProfileReducer;
