import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import verifyEmail from '../../../../../../shared/Utils/AutenticationApi/verifyEmail';
import { CircularProgress } from '@mui/material';
import EmailVerifiedModal from '../../components/EmailVerifiedModal';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import { environment } from 'apps/spellingheroweb/src/environments/environment';

const VerifyUser = () => {
  const { uid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isQuestionModalVisible, setQuestionModalVisible] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  useEffect(() => {
    if (uid) {
      handleVerifyEmail();
    }
  }, [uid]);
  const hideModal = () => {
    setQuestionModalVisible(false);
  };
  const handleVerifyEmail = async () => {
    const userInfo = {
      uid: uid,
    };
    const result = await verifyEmail(userInfo, environment?.apiUrl);
    if (result == 'Error') {
      setQuestionModalVisible(true);
      setApiErrorMessage(
        'We’re currently experiencing some issues. Please try again later.'
      );
    }
    setIsLoading(false);
  };
  return (
    <>
      {' '}
      {isLoading ? (
        <CircularProgress
          size={24}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      ) : apiErrorMessage !== '' ? (
        <CustomNoResponseModal
          isModalVisible={isQuestionModalVisible}
          onPress={hideModal}
          buttonText="OK"
          modalTitle="Oops!"
          text={apiErrorMessage}
          // icon={true}
        />
      ) : (
        <EmailVerifiedModal />
      )}
    </>
  );
};

export default VerifyUser;
