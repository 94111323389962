import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#f2c14e' : '#308fe8',
  },
}));

interface props {
  correctWordsPercentage: number;
}

export default function ProgressBar({ correctWordsPercentage }: props) {
  return (
    <BorderLinearProgress
      variant="determinate"
      value={correctWordsPercentage}
      className="progress-line-bar"
    />
  );
}
