import React, { useEffect, useState } from 'react';
import '../../scss/Form.scss';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import mailicon from '../../../assets/img/email-icon-dn.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Grid,
  Typography,
} from '@mui/material';
import sendCoppaEmail from 'shared/Utils/AutenticationApi/sendEmail';
import CustomModal from '../../components/CustomModal';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import { environment } from '../../../environments/environment';
import { authen } from '../../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';

export default function Emailverify() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [user, setUser] = useState<any>(null);

  const [isQuestionModalVisible, setQuestionModalVisible] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);
  const hideModal = () => {
    setIsModalVisible(false);
    setQuestionModalVisible(false);
  };
  const handleResendEmail = async () => {
    setIsLoading(true);
    const userInfo = {
      email_type: 'coppa',
      recipient_email: `${state?.email}`,
      recipient_full_name: `${state?.firstName} ${state?.lastName}`,
      is_fb_deep_link: false,
      continue_url: `${environment?.baseUrl}verify-user/${state?.uid}`,
    };
    const token = await (authen?.currentUser as any).getIdToken(true);
    const result = await sendCoppaEmail(userInfo, token, environment?.apiUrl);
    if (result == 'Error') {
      setQuestionModalVisible(true);
      setApiErrorMessage(
        'We’re currently experiencing some issues. Please try again later.'
      );
      setIsLoading(false);
    } else if (!result?.error_message) {
      setIsModalVisible(true);
      setIsLoading(false);
    } else {
      setApiErrorMessage(result?.error_message);
      setQuestionModalVisible(true);
    }
  };
  const handleChangeEmail = () => {
    navigate('/signup', {
      state: {
        isChecked: state?.isChecked,
        firstName: state?.firstName,
        lastName: state?.lastName,
        createPassword: state?.createPassword,
        month: state?.month,
        day: state?.day,
        year: state?.year,
      },
    });
  };
  return (
    <>
      <Navbar />
      <main className="main-container">
        <section className="quiz-signup">
          <Grid container spacing={2}>
            <Grid
              item
              lg={5}
              xs={12}
              sm={12}
              sx={{ paddingLeft: { lg: 0 } }}
              className="sidebar-top-spacing"
            >
              <Sidebar />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={7}
              xl={6}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                className="form-container"
                sx={{
                  paddingLeft: { lg: 3, xl: 5 },
                  marginLeft: { xl: 5 },
                  paddingRight: { xl: 5 },
                }}
              >
                <Typography
                  variant="h4"
                  className="d-flex align-items-center mb-4"
                >
                  <img src={mailicon} alt="Mail Icon" className="me-2" /> Verify
                  your Email ID
                </Typography>
                <Box className="mail-instruction">
                  <Typography variant="body1" className="title-text mt-4">
                    Please ask your parent to click the link in the email to
                    activate your account.
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body2" className="font-18">
                        It may take up to 1-2 minutes to receive the email.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2" className="font-18">
                        Check your spam folder if you didn’t receive the email.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2" className="font-18">
                        We sent the email to ({state?.email}){' '}
                        <Box
                          component="span"
                          className="cursor-pointer anchor-link"
                          onClick={handleChangeEmail}
                          sx={{ display: 'inline' }}
                        >
                          Change email?
                        </Box>
                      </Typography>
                    </li>
                  </ul>
                </Box>
                <Box textAlign="center" mt={4}>
                  <Link to="/login">
                    <Button
                      variant="contained"
                      className="btn btn-primary btn-verify"
                    >
                      Continue
                    </Button>
                  </Link>
                  <Typography
                    variant="body2"
                    className="mt-3 font-600 cursor-pointer"
                  >
                    Didn't get the email?{' '}
                    <Typography
                      component="span"
                      className="cursor-pointer anchor-link"
                      onClick={handleResendEmail}
                    >
                      Resend Email
                    </Typography>
                  </Typography>
                  {isLoading && (
                    <DialogActions style={{ justifyContent: 'center' }}>
                      <CircularProgress
                        sx={{
                          color: 'primary.main',
                        }}
                      />
                    </DialogActions>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </section>
      </main>
      <CustomModal
        isModalVisible={isModalVisible}
        onPress={hideModal}
        buttonText="OK"
        modalTitle="Verify your Email ID"
        text="Please ask your parent to click the link in the email to activate your account"
        icon={true}
      />
      <CustomNoResponseModal
        isModalVisible={isQuestionModalVisible}
        onPress={hideModal}
        buttonText="OK"
        modalTitle="Oops!"
        text={apiErrorMessage}
      />
      <Footer />
    </>
  );
}
