import {configureStore, createStore} from '@reduxjs/toolkit';
//import rootReducer from '../redux/reducers/index'; // Create this file later
import rootReducer, {RootState} from './reducers';
// const store = createStore(rootReducer);
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
// export default store;
const isNative = typeof navigator !== 'undefined' && navigator.product === 'ReactNative';

let store: any;
let persistor: any;

if (isNative) {
  // For React Native
  store = createStore(rootReducer);
  persistor = null; // No persistence for React Native
} else {
  // For React JS
  const persistConfig = {
    key: 'root',
    storage:AsyncStorage,
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  store = configureStore({
    reducer: persistedReducer,
  });

  persistor = persistStore(store);
}

export { store, persistor };