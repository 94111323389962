import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import trashicon from '../../assets/img/error.png';

interface props {
  isModalVisible: boolean;
  onPressbutton1: any;
  onPressbutton2: any;

  buttonText1: string;
  buttonText2: string;

  text: string;
  text2: string;

  modalTitle: string;
}

const CustomDeleteModal = ({
  isModalVisible,
  onPressbutton1,
  onPressbutton2,
  buttonText1,
  buttonText2,

  text,
  text2,
  modalTitle,
}: props) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isModalVisible) {
      setIsLoading(false);
    }
  }, [isModalVisible]);

  return (
    <Dialog
      open={isModalVisible}
      onClose={onPressbutton1}
      aria-labelledby="email-confirm-dialog"
    >
      <DialogTitle id="email-confirm-dialog" style={{ textAlign: 'center' }}>
        {
          <img
            src={trashicon}
            className="mx-auto d-block mb-2"
            width={60}
            alt="Mail Icon"
          />
        }
        {modalTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center' }}>
          {text}
        </DialogContentText>
        <DialogContentText style={{ textAlign: 'center', paddingTop: '2px' }}>
          {text2}
        </DialogContentText>
      </DialogContent>
      {isLoading ? (
        <DialogActions style={{ justifyContent: 'center' }}>
          <CircularProgress
            sx={{
              color: 'primary.main',
            }}
          />
        </DialogActions>
      ) : (
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={onPressbutton1} color="primary" variant="contained">
            {buttonText1}
          </Button>

          <Button
            onClick={() => {
              setIsLoading(true);
              onPressbutton2();
            }}
            color="primary"
            variant="contained"
          >
            {buttonText2}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomDeleteModal;
