import { Reducer } from "react";
import { ILevelState } from "../types/stateTypes";
import { AnyAction } from "@reduxjs/toolkit";

// reducer.js
const initialState = {
    error: null,
    leaderBoard: null
};

const levelReducer: Reducer<ILevelState, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_LEVEL':
        return {...state, result: action['payload'] };
      default:
        return state;
    }
  };
  
  export default levelReducer;
