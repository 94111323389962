import { contactUSType } from '../../types/globaltypes';
import axios from 'axios';

const ContactUsApi = async (contactUsInfo: contactUSType , apiUrl:string) => {
  try {
    const response = await axios.post(
     `${apiUrl}contactUs`,
      contactUsInfo,
      {
        method: 'POST',
        headers: {
          accept: 'application/json',
        },
        timeout:30000
      },
    );
    const apiData = response;
    return apiData.data;
  } catch (error) {
     return 'Error'
  }
};
export default ContactUsApi;