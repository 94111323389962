const monthsOfYear = [
  {label: 'January', value: 1},
  {label: 'February', value: 2},
  {label: 'March', value: 3},
  {label: 'April', value: 4},
  {label: 'May', value: 5},
  {label: 'June', value: 6},
  {label: 'July', value: 7},
  {label: 'August', value: 8},
  {label: 'September', value: 9},
  {label: 'October', value: 10},
  {label: 'November', value: 11},
  {label: 'December', value: 12},
];

const daysOfMonth = Array.from({length: 31}, (_, i) => ({
  label: `${i + 1}`,
  value: i + 1,
}));

const currentYear = new Date().getFullYear();

const calenderYears = Array.from({length: currentYear - 1949}, (_, i) => ({
  label: `${currentYear - i}`,
  value: currentYear - i,
}));

enum PhonogramBorderStyle {
  GREEN = '#2A9235',
  LIGHT_GREEN = '#9FFFA9',
  RED = '#EC1C24',
  LIGHT_RED = '#EE7575',
  GREY = '#DFDFDF',
}

export {monthsOfYear, daysOfMonth, calenderYears,PhonogramBorderStyle};
