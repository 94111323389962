import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from '@mui/material';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import '../../scss/ChangePassword.scss';
// import googleads980 from '../../../assets/img/google-ads-930.jpg';
// import googleads300 from '../../../assets/img/google-ads-300.png';
import { authen } from '../../firebase/firebase';
import ContactUsApi from '../../../../../../shared/Utils/ContactUsApi/Api';
import { onAuthStateChanged } from 'firebase/auth';
import validator from 'validator';
import CustomModal from '../../components/CustomModal';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import Footer from '../../components/Footer/Footer';
import { environment } from 'apps/spellingheroweb/src/environments/environment';
// import AdSenseAd from '../../components/AdSenseAd';

export default function Contactus() {
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [enable, setEnable] = useState<boolean>(false);
  const [enable1, setEnable1] = useState<boolean>(false);
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setEmail(currentUser?.isAnonymous ? '' : `${currentUser?.email}`);
    });
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    validateEmail(email);
  }, [email]);
  useEffect(() => {
    setEmail(
      authen.currentUser?.isAnonymous ? '' : `${authen?.currentUser?.email}`
    );
  }, [authen.currentUser]);
  const handleSubmit = async () => {
    setIsLoading(true);
    const contactUsInfo = {
      uid: authen.currentUser?.uid,
      email_id: email,
      message: message,
    };
    const response = await ContactUsApi(contactUsInfo, environment?.apiUrl);
    if (response == 'Error') {
      setIsQuestionModalVisible(true);
      setApiErrorMessage(
        'We’re currently experiencing some issues. Please try again later.'
      );
      setIsLoading(false);
    } else if (!response?.error_message) {
      setIsLoading(false);
      setIsModalVisible(true);

      setError('');
    } else {
      setIsLoading(false);
      setApiErrorMessage(response?.error_message);
      setIsQuestionModalVisible(true);
    }
  };

  const validateEmail = (input: string) => {
    setEmail(input);
    if (!email) {
      setError('');
      return;
    }
    if (validator.isEmail(email)) {
      setEnable(true);
      setError('');
    } else {
      setError('Please enter a valid email address.');
      setEnable(false);
    }
  };
  const handleMessageField = (inputText: string) => {
    setMessage(inputText);
    if (!inputText.trim()) {
      setEnable1(false);
    } else {
      setEnable1(true);
    }
  };

  const hideNoQuestionModal = () => {
    setIsQuestionModalVisible(false);
  };

  const hideModal = () => {
    setIsModalVisible(!isModalVisible);
    setMessage('');
  };

  return (
    <>
      <Navbar currentPage="contactus" />
      <Header />

      <main className="page-bg overflow-hidden change-psd-page">
        {/* <Box
          className="googleAds top mx-auto"
          sx={{ textAlign: 'center', paddingBottom: '30px', }}
        > */}
        {/* <img src={googleads980} alt="Google Ads 980" /> */}
        {/* <AdSenseAd /> */}
        {/* </Box> */}
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3} className="pt-0">
              {/* <Box
                className="googleAds top mx-auto"
                sx={{ textAlign: 'center' }}
              >
                <img src={googleads300} alt="Google Ads 300" />
              </Box> */}
            </Grid>
            <Grid item xs={12} lg={6}>
              <section className="quiz-step change-psd">
                <Typography
                  variant="h4"
                  gutterBottom
                  fontWeight="700 !important"
                  fontSize="28px !important"
                  fontFamily="roboto"
                >
                  Contact Us
                </Typography>

                <Box className="box-container" sx={{ mt: 3 }}>
                  <form>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} md={3}>
                            <Typography
                              variant="body1"
                              fontSize="20px"
                              fontFamily="roboto"
                              className="font-600"
                            >
                              Your email
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <TextField
                              className="input-change"
                              fullWidth
                              type="email"
                              variant="outlined"
                              placeholder="example@email.com"
                              value={email}
                              onChange={(e) => validateEmail(e.target.value)}
                            />
                            {error && (
                              <Typography style={{ color: 'red' }}>
                                {error}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} md={3}>
                            <Typography
                              variant="body1"
                              fontSize="20px"
                              fontFamily="roboto"
                              className="font-600 font-20"
                            >
                              Message
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <TextField
                              placeholder="Type in here…"
                              multiline
                              minRows={7}
                              variant="outlined"
                              fullWidth
                              value={message}
                              onChange={(e) =>
                                handleMessageField(e.target.value)
                              }
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '&.Mui-focused fieldset': {
                                    borderColor: 'primary.main',
                                    borderWidth: '2px',
                                  },
                                },
                              }}
                            />
                            {errorMessage ? (
                              <Typography style={{ color: 'red' }}>
                                {errorMessage}
                              </Typography>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box sx={{ textAlign: 'center', paddingTop: '60px' }}>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: 'none',
                          width: '400px',
                          maxWidth: '100%',
                        }}
                        className="btn btn-primary br-100 font-24 btn-submit-contact"
                        disabled={!(enable === true && enable1 === true)}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                </Box>
              </section>

              <CustomNoResponseModal
                isModalVisible={isQuestionModalVisible}
                onPress={hideNoQuestionModal}
                buttonText="OK"
                modalTitle="Oops!"
                text={apiErrorMessage}
              />

              <CustomModal
                isModalVisible={isModalVisible}
                onPress={hideModal}
                buttonText="Ok"
                text="Your message has been successfully submitted! Our team will be in touch with you shortly."
                modalTitle="Success"
                icon={false}
              />
            </Grid>
            <Grid item xs={12} lg={3} sx={{ pb: 3 }} className="pt-0">
              {/* <Box className="googleAds top " sx={{ textAlign: 'center' }}>
                <img src={googleads300} alt="Google Ads 300" />
              </Box> */}
            </Grid>
          </Grid>
        </Container>
      </main>
      <Footer />
    </>
  );
}
