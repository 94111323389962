import { doc, getDoc } from 'firebase/firestore';
import { authen, db } from '../firebase/firebase';
import RemoteErrorLog from './api';

export const fetchCookieUsers = async (uid:string) => {
  try {
    const docRef = doc(db, 'user_profile', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return true;
    } else {
      return false; // Or handle the case where the document doesn't exist
    }
  } catch (error) {
    RemoteErrorLog(error, 'firebase-services.ts');
    return "Error"

  }
};
export const fetchWordData = async (word:any) => {
  try {
    const docRef = doc(db, 'fdp_words_audio', word);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data;
    } else {
      return null; 
    }
  } catch (error) {
    RemoteErrorLog(error, 'firebase-services.ts');

    return null; 
  }
};
export const isuserPremium = async () => {
  const token = await (authen?.currentUser as any)?.getIdToken(true);
  const decodedToken:any = await authen?.currentUser?.getIdTokenResult();

  const value = decodedToken?.claims?.stripeRole ? true : false || decodedToken?.claims?.revenueCatEntitlements && decodedToken?.claims?.revenueCatEntitlements?.[0] ? true : false;
  return value;
};