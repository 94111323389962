import React, { useState } from 'react';
import './Cookies/Cookies.scss';
import { Box, Button, Dialog, Typography } from '@mui/material';

export default function LevelUnlock({
  isModalVisible,
  onPress,
  buttonText,
  text,
  modalTitle,
  text2,
  currentLevelUrl,
}: any) {
  return (
    <Box className="container mt-3">
      <Dialog open={isModalVisible} onClose={onPress}>
        <Box sx={{ borderRadius: '12px' }}>
          <Box
            className="manage-box"
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '12px',
            }}
          >
            <Typography variant="body1" className="font-600 font-20">
              {modalTitle}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: '16px',
                color: '#333333',
                fontWeight: 400,
                fontFamily: 'poppins',
              }}
            >
              {text}
            </Typography>
            <img src={currentLevelUrl} alt="unlock-img" />
            <Typography
              variant="body1"
              sx={{
                fontSize: '16px',
                color: '#333333',
                fontWeight: 400,
                fontFamily: 'poppins',
              }}
            >
              {text2}
            </Typography>
            <Box mt={2}>
              <Button
                variant="contained"
                className="btn btn-primary br-100"
                fullWidth
                sx={{ textTransform: 'none', fontFamily: 'poppins' }}
                onClick={onPress}
              >
                Continue Learning
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
