import { uuidType } from "../../types/globaltypes";
import axios from "axios";

const deleteAccountApi = async (uid: uuidType,token:string , apiUrl:string) => {
    try {
      const response = await axios.post(
       `${apiUrl}deleteUser`, 
        uid,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`
          },
          timeout:30000
        },
      );
      const apiData = response;
      return apiData.data;
    } catch (error) {
       return 'Error'
    }
  };
export default deleteAccountApi;  