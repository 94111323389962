import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import mailicon from '../../../assets/img/email-icon-dn.svg';
import '../../scss/Form.scss';
import validator from 'validator';
import { sendPasswordResetEmail } from 'firebase/auth';
import { authen } from '../../firebase/firebase';
import checkEmailRegistered from '../../../../../../shared/Utils/AutenticationApi/forgotPassword';
import CustomModal from '../../components/CustomModal';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import { environment } from 'apps/spellingheroweb/src/environments/environment';

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [isQuestionModalVisible, setQuestionModalVisible] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
  };
  const [resetEmail, setResetEmail] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [notRegisteredModalVisible, setNotRegisteredModalVisible] =
    useState(false);
  const [error, setError] = useState<string>('');
  const [enable, setEnable] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);
  const hideModal = () => {
    navigate('/login');
  };
  const hideNoResponseModal = () => {
    setQuestionModalVisible(false);
  };
  const handleEmailRegister = async () => {
    setIsLoading(true);
    const userEmail = {
      email_id: resetEmail,
    };
    const registeredEmail = await checkEmailRegistered(
      userEmail,
      environment?.apiUrl
    );
    if (registeredEmail == 'Error') {
      setQuestionModalVisible(true);
      setApiErrorMessage(
        'We’re currently experiencing some issues. Please try again later.'
      );
    } else if (registeredEmail !== null && registeredEmail !== 'Error') {
      setIsLoading(false);
      setIsModalVisible(true);
      sendPasswordResetEmail(authen, resetEmail);
    } else {
      setIsLoading(false);
      setNotRegisteredModalVisible(true);
    }
    setIsLoading(false);
  };
  const validateEmail = (input: string) => {
    setResetEmail(input);
    if (validator.isEmail(input)) {
      setError('');
      setEnable(false);
    } else {
      setError('Please enter a valid email address.');
      setEnable(true);
    }
  };
  const showModalView = async () => {
    setNotRegisteredModalVisible(false);
  };
  return (
    <>
      <Navbar />
      <main className="main-container">
        <section className="quiz-signup">
          <Grid container spacing={3}>
            <Grid item lg={5} xs={12} sm={12} className="sidebar-top-spacing">
              <Sidebar />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Box className="form-container">
                <Typography variant="h4" gutterBottom>
                  Forgot Password
                </Typography>
                <Typography variant="body1" paragraph>
                  Enter the email address associated with your account and we’ll
                  send you an email to reset your password.
                </Typography>
                <form>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1" className="font-600">
                        Your registered email
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="example@email.com"
                        value={resetEmail}
                        onChange={(e) => validateEmail(e.target.value)}
                      />
                      {error && (
                        <Typography style={{ color: 'red' }}>
                          {error}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    style={{ marginTop: '2rem' }}
                  >
                    <Grid item xs={12} md={8}>
                      {isLoading ? (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: 'primary.main',
                          }}
                        />
                      ) : (
                        <Button
                          className="btn btn-primary"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={enable}
                          onClick={handleEmailRegister}
                        >
                          Continue
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </section>
      </main>
      <CustomModal
        isModalVisible={isModalVisible}
        onPress={hideModal}
        buttonText="OK"
        modalTitle="Verify your Email ID"
        text="Please check your email for a password reset link. We’ve sent an email with instructions."
        icon={true}
      />
      <CustomModal
        isModalVisible={notRegisteredModalVisible}
        onPress={showModalView}
        buttonText="OK"
        modalTitle="Oops!"
        text="The email you’ve entered doesn’t match our records. Please input a registered email to receive instructions for resetting your password."
        icon={true}
      />
      <CustomNoResponseModal
        isModalVisible={isQuestionModalVisible}
        onPress={hideNoResponseModal}
        buttonText="OK"
        modalTitle="Oops!"
        text={apiErrorMessage}
        // icon={true}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="email-confirm-dialog"
      >
        <DialogTitle id="email-confirm-dialog" style={{ textAlign: 'center' }}>
          <img
            src={mailicon}
            className="mx-auto d-block mb-2"
            width={60}
            alt="Mail Icon"
          />
          Verify your Email ID
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }}>
            Please check your email for a password reset link. We’ve sent an
            email with instructions.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleClose} color="primary" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </>
  );
}
