import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Error404 from '../../../assets/img/Error404.png';
import Erroriconarrow from '../../../assets/img/error-icon.png';
import './PageNotFound.scss';
import Footer from '../../components/Footer/Footer';

import Navbar from '../../components/Navbar/Navbar';
import { Link } from 'react-router-dom';
export default function PageNotFound() {
  return (
    <main className="page-bg overflow-hidden">
      <Navbar />
      <Container
        component="section"
        sx={{
          maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: {
              xs: '20px',
              md: '90px',
            },
            justifyContent: 'center',
            paddingBottom: {
              xs: '40px',
              md: '100px',
            },
            paddingTop: {
              xs: '66px',
              md: '150px',
            },
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              flexBasis: {
                xs: '100%',
                md: '30%',
              },
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              fontSize="40px"
              fontWeight="700"
              lineHeight="1.6"
              fontFamily="roboto"
              color="#000"
            >
              Oops....
            </Typography>
            <Typography
              className="error-text-opps"
              variant="body2"
              gutterBottom
              fontWeight="400"
              lineHeight="1.2"
              fontFamily="roboto"
              color="#000"
              paddingBottom="15px"
            >
              It seems like the page you are looking does not exist.
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              fontSize="16px"
              fontWeight="400"
              lineHeight="1.6"
              fontFamily="roboto"
              color="#4B4B4B"
              paddingBottom="40px"
            >
              But don't worry, we've got a lot of learning opportunities waiting
              for you. Let's get back on track! To explore our spelling learning
              game
            </Typography>
            <button
              className="  btn btn-primary  br-5"
              style={{
                width: '177px',
                height: '45px',
                maxWidth: '100%',
                fontFamily: 'Roboto',

                fontWeight: '400',
                fontSize: '16px',
                borderRadius: '5px !important',
              }}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', color: 'white' }}
                component={Link}
                to="/"
              >
                {' '}
                <img src={Erroriconarrow} />
                Back to home
              </Box>
            </button>
          </Box>

          <Box>
            <img src={Error404} alt="error-404" />
          </Box>
        </Box>
      </Container>
      <Footer />
    </main>
  );
}
