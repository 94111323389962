import React from 'react';
import './Cookies/Cookies.scss';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
const EmailVerifiedModal = () => {
  return (
    <div className="container mt-3">
      <Dialog open={true}>
        <DialogContent>
          <Box className="manage-box" sx={{ minHeight: 'unset' }}>
            <Typography variant="body1" className="font-500 font-18">
              Your email has been verified.
            </Typography>
            <Typography variant="body1" className="font-500 font-18">
              Your new account is set up and ready for sign-in via the app or
              website.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EmailVerifiedModal;
