import axios from "axios";
const submitResult = async (resultInfo: any , apiUrl:string) => {
    //const dispatch = useDispatch();
       try {
      const response = await axios.post(
        `${apiUrl}save`,   
        resultInfo,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          timeout:30000
        },
      );
      const apiData = response;
      //dispatch(setQuestions(apiData.data));
      return apiData.data;
    } catch (error) {
      return 'Error'
    }
}
export default submitResult;  