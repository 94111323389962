import { reportWordType } from "../../types/globaltypes";
import axios from "axios";

const ReportWord = async (wordInfo: reportWordType , apiUrl:string) => {
    try {
      const response = await axios.post(
        `${apiUrl}reportAbuse`,   
        wordInfo,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          timeout:30000
        },
      );
      const apiData = response;
      return apiData.data;
    } catch (error) {
      return 'Error'
    }
  };
export default ReportWord;  