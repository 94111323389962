import React from "react";
import { Navigate } from "react-router-dom";

const LoggedIn = ({ children }: any) => {
  const userData = localStorage.getItem("userData") ?? "{}";
  const data =JSON.parse(userData )
  if (JSON.parse(userData )?.emailVerified==true) return <Navigate to="/home" />;
  return children;
};

export default LoggedIn;
