// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Navigate, useLocation } from 'react-router-dom';
import styles from './app.module.css';

import NxWelcome from './nx-welcome';
import Routes from './routes';
export const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match('/.*/$')) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ''),
          search: location.search,
        }}
      />
    );
  } else return null;
};
export function App() {
  return (
    <div>
      <RemoveTrailingSlash />
      <Routes />
    </div>
  );
}

export default App;
