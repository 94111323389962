import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import App from './app/app';
import './app/scss/common.scss';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from 'shared/store/src/lib/store';
import { PersistGate } from 'redux-persist/integration/react';
// require('dotenv').config();
import { environment } from './environments/environment';
import { ErrorBoundary } from 'react-error-boundary';
import FallbackComponent from './app/components/FallbackComponent';
import RemoteErrorLog from './app/Utils/api';
// import ErrorBoundary from './app/components/ErrorBoundary/ErrorBoundary';

const fallback = () => <div>something went wrong</div>;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary
          FallbackComponent={FallbackComponent}
          onError={(error) => RemoteErrorLog(error)}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </StrictMode>
);
