import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import '../../scss/ChangePassword.scss';
// import googleads980 from '../../../assets/img/google-ads-930.jpg';
// import googleads300 from '../../../assets/img/google-ads-300.png';
import {
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { authen } from '../../firebase/firebase';
import '../../scss/Quiz.scss';

import '../../scss/Form.scss';
import CustomModal from '../../components/CustomModal';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import RemoteErrorLog from '../../Utils/api';

export default function ChangePassword() {
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [error1, setError1] = useState<string>('');
  const [error2, setError2] = useState<string>('');
  const [enable, setEnable] = useState<boolean>(false);
  const [enable1, setEnable1] = useState<boolean>(false);
  const [enable2, setEnable2] = useState<boolean>(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState<any>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser); // Update the user state
    });
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (confirmPassword) {
      setPasswordsMatch(newPassword === confirmPassword);
    }
  }, [confirmPassword, newPassword]);
  const hideModal = () => {
    setIsModalVisible(!isModalVisible);
    navigate('/home');
  };

  const reauthenticate = () => {
    var user = authen.currentUser;
    var cred = EmailAuthProvider.credential(
      user?.email as any,
      currentPassword
    );
    return reauthenticateWithCredential(user!, cred);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const showModalView = () => {
    setIsLoading(false);
    setIsModalVisible(!isModalVisible);
  };

  const updateUserPassword = () => {
    setIsLoading(true);
    reauthenticate()
      .then(() => {
        var user = authen.currentUser;
        updatePassword(user!, newPassword)
          .then(() => {
            showModalView();
          })
          .catch((error: Error) => {
            RemoteErrorLog(error, 'ChangePassword.tsx');
          });
      })
      .catch((error: Error) => {
        setIsLoading(false);
        window.alert('Current Password is incorrect.');
        RemoteErrorLog(error, 'ChangePassword.tsx');
      });
  };

  const validateCurrentPassword = (text: string) => {
    if (text.length < 6) {
      setError('The password must be at least 6 characters long.');
      setEnable(false);
    } else if (!text.trim()) {
      setError1('The password cannot have space characters.');
      setEnable1(false);
    } else {
      setEnable(true);
      setError('');
    }
  };
  const validateNewPassword = (text1: string) => {
    if (text1.length < 6) {
      setError1('The password must be at least 6 characters long.');
      setEnable1(false);
    } else if (!text1.trim()) {
      setError1('The password cannot have space characters.');
      setEnable1(false);
    } else {
      setEnable1(true);
      setError1('');
    }
  };
  const validateConfirmNewPassword = (text2: string) => {
    if (text2.length < 6) {
      setEnable2(false);
    } else {
      setEnable2(true);
    }
  };
  return (
    <>
      <header>
        <Navbar currentPage="changePassword" />
      </header>

      <Header />

      <main className="page-bg overflow-hidden change-psd-page">
        {/* <Box
          className="googleAds top mx-auto"
          sx={{ textAlign: 'center', paddingBottom: '30px' }}
        >
          <img src={googleads980} alt="Google Ads 980" />
        </Box> */}
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3} className="pt-0">
              {/* <Box
                className="googleAds top mx-auto"
                sx={{ textAlign: 'center' }}
              >
                <img src={googleads300} alt="Google Ads 300" />
              </Box> */}
            </Grid>
            <Grid item xs={12} lg={6}>
              <section className="quiz-step change-psd">
                <Typography variant="h4" gutterBottom fontWeight="600">
                  Change Password
                </Typography>

                <Box className="box-container" sx={{ mt: 3 }}>
                  <form>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} md={6}>
                            <Typography variant="body1" className="font-600">
                              Previous Password
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              className="input-change"
                              fullWidth
                              type="password"
                              variant="outlined"
                              placeholder="Enter your previous password"
                              value={currentPassword}
                              onChange={(e) => {
                                setCurrentPassword(e.target.value);
                                validateCurrentPassword(e.target.value);
                              }}
                            />
                            {error && (
                              <Typography style={{ color: 'red' }}>
                                {error}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} md={6}>
                            <Typography variant="body1" className="font-600">
                              New Password
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              type="password"
                              variant="outlined"
                              placeholder="Enter your new password"
                              value={newPassword}
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                                validateNewPassword(e.target.value);
                              }}
                            />
                            {error1 && (
                              <Typography style={{ color: 'red' }}>
                                {error1}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sx={{ paddingTop: 0 }}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} md={6}>
                            <Typography variant="body1" className="font-600">
                              Confirm New Password
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              type="password"
                              variant="outlined"
                              placeholder="Confirm your new password"
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                validateConfirmNewPassword(e.target.value);
                              }}
                            />
                            {error2 && (
                              <Typography style={{ color: 'red' }}>
                                {error2}
                              </Typography>
                            )}
                            {!passwordsMatch && (
                              <Typography style={{ color: 'red' }}>
                                Passwords do not match. Please try again.
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Box className="btn-change">
                          {isLoading ? (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: 'primary.main',
                                // position: 'absolute',
                                // top: '50%',
                                // left: '50%',
                                // marginTop: '-12px',
                                // marginLeft: '-12px',
                              }}
                            />
                          ) : (
                            <Button
                              // type="btn"
                              variant="contained"
                              className="btn btn-primary "
                              disabled={
                                !(
                                  enable === true &&
                                  enable1 === true &&
                                  enable2 &&
                                  passwordsMatch
                                )
                              }
                              onClick={updateUserPassword}
                            >
                              Change Password
                            </Button>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </section>
            </Grid>
            <Grid item xs={12} lg={3} sx={{ pb: 3 }} className="pt-0">
              {/* <Box className="googleAds top " sx={{ textAlign: 'center' }}>
                <img src={googleads300} alt="Google Ads 300" />
              </Box> */}
            </Grid>
          </Grid>
        </Container>
      </main>
      <Footer />
      <CustomModal
        isModalVisible={isModalVisible}
        onPress={hideModal}
        buttonText="Ok"
        text="Password changed successfully"
        modalTitle="Success"
        icon={false}
      />
    </>
  );
}
