import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useEffect } from 'react';
import erroricon from '../../assets/img/error.png';
interface props {
  isModalVisible: any;
  onPress: any;
  buttonText: any;
  text: any;
  modalTitle: string;
  icon?: boolean;
  color?: string;
}
const CustomNoResponseModal = ({
  isModalVisible,
  onPress,
  buttonText,
  text,
  modalTitle,
  icon,
  color,
}: props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog
      open={isModalVisible}
      onClose={onPress}
      aria-labelledby="email-confirm-dialog"
    >
      <DialogTitle id="email-confirm-dialog" style={{ textAlign: 'center' }}>
        {icon == false ? null : (
          <img
            src={erroricon}
            className="mx-auto d-block mb-2"
            width={60}
            alt="Mail Icon"
          />
        )}
        {modalTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center' }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        {color ? (
          <Button onClick={onPress} color="success" variant="contained">
            {buttonText}
          </Button>
        ) : (
          <Button onClick={onPress} color="primary" variant="contained">
            {buttonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomNoResponseModal;
