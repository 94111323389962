import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const CircularProgressWithLabel = ({ score, total, maxScore }: any) => {
  const calculateProgressPercentage = () => {
    const percentage = (Number(score) / Number(maxScore)) * 100;
    return percentage;
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        size={200}
        variant="determinate"
        value={calculateProgressPercentage()}
        sx={{ color: '#FFC107' }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          fontFamily="roboto"
          fontWeight="700"
          fontSize="32px"
        >
          {score}/{total}
        </Typography>
      </Box>
    </Box>
  );
};
export default CircularProgressWithLabel;
