import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import '../../scss/ChangePassword.scss';
// import googleads980 from '../../../assets/img/google-ads-930.jpg';
// import googleads300 from '../../../assets/img/google-ads-300.png';
import { useDispatch, useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import '../../components/TabProfile/TabProfile.scss';
import '../../pages/EditProfile/EditProfile.scss';
import { authen, db, storage } from '../../firebase/firebase';
import updateUserProfile from 'shared/Utils/UpdateProfileApi/Api';
import { onAuthStateChanged, updateProfile } from 'firebase/auth';
import { doc, getDoc } from '@firebase/firestore';
import { setUserProfile } from 'shared/store/src/lib/actions/userprofile';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { setAvatar } from '../../../../../../shared/store/src/lib/actions/avatars';
import RemoteErrorLog from '../../Utils/api';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import { useNavigate } from 'react-router-dom';
import { environment } from 'apps/spellingheroweb/src/environments/environment';

export default function EditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector((state: any) => state.userprofile);
  const avatarImageurls = useSelector((state: any) => state.avatarurls);
  const [userScreenName, setUserScreenName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [userDisplayName, setUserDisplayName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessage1, setErrorMessage1] = useState<string>('');
  const [enable, setEnable] = useState<boolean>(true);
  const [enable1, setEnable1] = useState<boolean>(true);
  const [imageTab, setImageTab] = useState<any>([]);
  const [user, setUser] = useState<any>();
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(
    profile?.user?.img_uri_https
  );
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleChangeScreenName = (text: string) => {
    setUserScreenName(text);
    if (!text.trim()) {
      setErrorMessage1('Screen name is required.');
      setEnable1(false);
      return;
    } else {
      setErrorMessage1('');
      setEnable1(true);
    }
  };
  const hideNoQuestionModal = () => {
    setIsQuestionModalVisible(false);
  };
  const handleChangeDisplayName = (text: string) => {
    setUserDisplayName(text);
    if (!text.trim()) {
      setErrorMessage('Display name is required.');
      setEnable(false);
      return;
    } else {
      setErrorMessage('');
      setEnable(true);
    }
  };

  const handleUpdateButton = async () => {
    setIsLoading(true);
    try {
      const updateProfileType = {
        uid: authen?.currentUser?.uid ? authen?.currentUser?.uid : '',
        screen_name: userScreenName,
        img_uri_https: selectedAvatar,
        full_name: userDisplayName,
      };
      const updateUser = await updateUserProfile(
        updateProfileType,
        environment?.apiUrl
      );
      if (updateUser == 'Error') {
        setIsQuestionModalVisible(true);
        setApiErrorMessage(
          'We’re currently experiencing some issues. Please try again later.'
        );
        setIsLoading(false);
      } else if (!updateUser?.error_message) {
        if (!authen.currentUser) {
          return;
        }

        try {
          await updateProfile(authen.currentUser, {
            displayName: userDisplayName,
          });
        } catch (error) {
          RemoteErrorLog(error, 'EditProfile.tsx');
        }
        try {
          const docRef = doc(db, 'user_profile', authen?.currentUser?.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            dispatch(setUserProfile(data));
            navigate('/profile');
          } else {
          }
        } catch (error) {
          RemoteErrorLog(error, 'EditProfile.tsx');
        }
      } else {
        setApiErrorMessage(updateUser?.error_message);
        setIsQuestionModalVisible(true);
      }

      setIsLoading(false);
    } catch (error) {
      RemoteErrorLog(error, 'EditProfile.tsx');
    }
  };
  useEffect(() => {
    if (profile?.user?.screen_name && !authen?.currentUser?.isAnonymous) {
      setUserScreenName(profile?.user?.screen_name);
      setUserDisplayName(profile?.user?.full_name);
    }
    if (!avatarImageurls.length) {
      setIsLoading(true);

      fetchAvatars();
    } else {
      setImageTab(avatarImageurls);
      setIsLoading(false);
    }
  }, [profile, avatarImageurls]);

  const fetchAvatars = async () => {
    if (!avatarImageurls.length) {
      try {
        const storageRef = await ref(storage, 'images/avatar');
        const { items } = await listAll(storageRef);

        const downloadPromises = items.map((item) => getDownloadURL(item));
        const urls = await Promise.all(downloadPromises);

        setImageTab(urls);
        setIsLoading(false);
        dispatch(setAvatar(urls));
      } catch (e) {
        RemoteErrorLog(e, 'EditProfile.tsx');

        setIsLoading(false);
        // Handle error appropriately, e.g., show an error message to the user
      } finally {
        // Consider uncommenting setIsLoading(false); if you're using a loading state
      }
    }
  };
  return (
    <>
      <Navbar />
      <Header />

      <main className="page-bg overflow-hidden change-psd-page">
        {/* <Box
          className="googleAds top mx-auto"
          sx={{ textAlign: 'center', paddingBottom: '30px' }}
        >
          <img src={googleads980} alt="Google Ads 980" />
        </Box> */}
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3} className="pt-4">
              {/* <Box
                className="googleAds top mx-auto"
                sx={{ textAlign: 'center' }}
              >
                <img src={googleads300} alt="Google Ads 300" />
              </Box> */}
            </Grid>
            <Grid item xs={12} lg={6}>
              <section className="quiz-step change-psd">
                <Typography
                  variant="h4"
                  gutterBottom
                  fontFamily="roboto !important"
                  fontSize="28px !important"
                  fontWeight="700 !important"
                >
                  Edit Profile
                </Typography>
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100vh',
                    }}
                  >
                    {' '}
                    <CircularProgress
                      size={56}
                      sx={{
                        color: 'primary.main',
                      }}
                    />{' '}
                  </Box>
                ) : (
                  <Box className="box-container" sx={{ mt: 3 }}>
                    <form>
                      <Grid container spacing={3}>
                        <Grid item xs={12} className="pb-3">
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={4}>
                              <Typography variant="body1" className="font-600">
                                Your screen name*
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <TextField
                                className="input-change"
                                fullWidth
                                variant="outlined"
                                placeholder="screen name"
                                value={userScreenName}
                                onChange={(e) =>
                                  handleChangeScreenName(e.target.value)
                                }
                              />
                              <Typography
                                variant="body1"
                                gutterBottom
                                fontFamily="poppins !important"
                                fontSize="13px !important"
                                color="#878787"
                                fontWeight="400 !important"
                                marginTop="10px"
                              >
                                Your screen name is how other people will see
                                you.
                              </Typography>
                              {errorMessage1 && (
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  fontFamily="poppins !important"
                                  fontSize="13px !important"
                                  color="red"
                                  fontWeight="400 !important"
                                  marginTop="10px"
                                >
                                  {errorMessage1}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={4}>
                              <Typography variant="body1" className="font-600">
                                Your display name*
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <TextField
                                className="input-change"
                                fullWidth
                                variant="outlined"
                                placeholder="screen name"
                                value={userDisplayName}
                                onChange={(e) =>
                                  handleChangeDisplayName(e.target.value)
                                }
                              />

                              {errorMessage && (
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  fontFamily="poppins !important"
                                  fontSize="13px !important"
                                  color="red"
                                  fontWeight="400 !important"
                                  marginTop="10px"
                                >
                                  {errorMessage}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>

                    <Box sx={{ textAlign: 'center', paddingTop: '20px' }}>
                      <img
                        src={selectedAvatar}
                        alt="edit-pic"
                        style={{ width: '20%' }}
                      />
                      <Typography
                        variant="body1"
                        gutterBottom
                        className="edit-heading-text"
                        color="#333333"
                        paddingTop="20px"
                        fontWeight="500 !important"
                      >
                        Choose your profile image from below
                      </Typography>
                    </Box>
                    {!authen?.currentUser?.isAnonymous && (
                      <Box className="avatar-image-grid">
                        {imageTab.map((url: string, index: number) => (
                          <img
                            src={url}
                            alt="avatar-img-2"
                            style={{ width: '68%' }}
                            className="cursor-pointer"
                            onClick={() => {
                              if (!authen?.currentUser?.isAnonymous) {
                                setSelectedAvatar(url);
                              }
                            }}
                          />
                        ))}
                      </Box>
                    )}

                    {authen?.currentUser?.isAnonymous && (
                      <Box className=" avatar-image-grid">
                        {imageTab.map((url: any, index: number) => (
                          <Box className="lock-avatar  ">
                            <>
                              <img
                                src={url}
                                alt="avatar-img-2"
                                style={{ width: '90px' }}
                              />
                              <LockIcon
                                className="lock-icon"
                                sx={{ fontSize: '40px', color: '#FF69B4' }}
                              />
                            </>
                          </Box>
                        ))}
                      </Box>
                    )}

                    <Grid item xs={12}>
                      <Box
                        className="btn-change"
                        sx={{ paddingTop: '62px !important' }}
                      >
                        <Button
                          sx={{
                            textTransform: 'none',
                            width: '50%',
                            maxWidth: '100%',
                          }}
                          type="submit"
                          variant="contained"
                          className="btn btn-primary "
                          disabled={
                            authen?.currentUser?.isAnonymous ||
                            !(enable && enable1)
                          }
                          onClick={handleUpdateButton}
                        >
                          Update
                        </Button>
                      </Box>
                    </Grid>
                  </Box>
                )}
              </section>
            </Grid>
            <Grid item xs={12} lg={3} sx={{ pb: 3 }} className="pt-4">
              {/* <Box className="googleAds top " sx={{ textAlign: 'center' }}>
                <img src={googleads300} alt="Google Ads 300" />
              </Box> */}
            </Grid>
          </Grid>
        </Container>
        <CustomNoResponseModal
          isModalVisible={isQuestionModalVisible}
          onPress={hideNoQuestionModal}
          buttonText="OK"
          modalTitle="Oops!"
          text={apiErrorMessage}
        />
      </main>
    </>
  );
}
