import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import './ProgressTab.scss';
import ProgressTable from '../../components/ProgressTable/ProgressTable';
import { useSelector } from 'react-redux';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="scrollable"
          allowScrollButtonsMobile
          scrollButtons
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="tab-starting"
        >
          <Tab
            className="tab-1 tab-progress"
            label="Incorrect Answers"
            {...a11yProps(0)}
          />
          <Tab
            className="tab-2 tab-progress"
            label="Correct Answers"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      {/* authen?.currentUser?.isAnonymous */}
      <Box
        sx={{ height: 'unset !important' }}
        className="tab-container scrolling-profile-content  tab-conatiner-progress"
      >
        {/* incorrect Answer */}
        <TabPanel value={value} index={0}>
          <ProgressTable view={'1'} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ProgressTable view="2" />
        </TabPanel>
      </Box>
    </Box>
  );
}
