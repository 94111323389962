import { createReducer } from '@reduxjs/toolkit';
import { AnyAction, Reducer } from 'redux';
import { IResultStatee } from '../types/stateTypes';

// Define the Question type


const INITIAL_STATE: IResultStatee = {
    error: null,
    result: null
}
interface Action {
  type: string;
  payload: any; // Specify the actual type if possible
}

const resultReducer: Reducer<IResultStatee, Action> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_RESULT':
      return {...state, result: action['payload'] };
    default:
      return state;
  }
};

export default resultReducer;