import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/Navbar/Navbar';
import { Box, CircularProgress } from '@mui/material';
import Footer from '../../components/Footer/Footer';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../../firebase/firebase';
import { setStaticPages } from 'shared/store/src/lib/actions/staticPages';
import RemoteErrorLog from '../../Utils/api';
import { Helmet } from 'react-helmet';
const TermsOfService = () => {
  const pagesUrl = useSelector((state: any) => state.pagesUrl);
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad: React.ReactEventHandler<HTMLIFrameElement> = () => {
    setIsLoading(false);
  };
  const dispatch = useDispatch();

  const fetchPages = async () => {
    if (!pagesUrl.length) {
      try {
        try {
          const storageRef = await ref(storage, 'pages');
          const result_1 = await listAll(storageRef);
          const urls = await Promise.all(
            result_1.items.map((pages) => getDownloadURL(pages))
          );
          dispatch(setStaticPages(urls));
        } catch (e) {
          RemoteErrorLog(e, 'TermsOfService.tsx');
        }
      } finally {
      }
    }
  };
  useEffect(() => {
    fetchPages();
  }, []);
  return (
    <main>
      <Helmet>
        <title>Terms & Conditions | Fun Spelling gaming app for Students</title>
        <meta
          name="description"
          content="Terms & Conditions of Spelling Hero app, which is a spelling gaming app for students to learn to spell by fun and engaging spelling word games."
        />
        <link
          rel="canonical"
          href="https://spellinghero.app/terms-of-service"
        ></link>
      </Helmet>
      <header>
        {' '}
        <Navbar currentPage="termsofservice" />
      </header>
      {/* <Header /> */}
      {isLoading && (
        <section>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
          >
            {' '}
            <CircularProgress
              size={56}
              sx={{
                color: 'primary.main',
              }}
            />{' '}
          </Box>
        </section>
      )}
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Box className="term-heading">
          <h1
            style={{
              fontSize: 40,
              color: '#333',
              textAlign: 'center',
              letterSpacing: 5,
              fontFamily: 'roboto',
              paddingTop: '20px',
            }}
          >
            Terms & Conditions
          </h1>
        </Box>
        <iframe
          src={pagesUrl[2]}
          title="Full Screen Iframe"
          onLoad={handleLoad}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            minHeight: '100vh',
          }}
        />
      </div>
      <Footer />
    </main>
  );
};

export default TermsOfService;
