import { combineReducers } from 'redux';
import questionReducer from '../reducers/questionReducers'; // Assuming you have this file
import userProfileReducer from '../reducers/userProfileReducer';
import resultReducer from './resultReducers';
import filterReducer from './filterReducers';
import questionDetailReducer from './questionDetailReducer';
import levelReducer from './levelReducer';
import avatarReducer from './avatarReducer';
import badgeReducer from './badgesReducer';
import levelImageReducer from './levelImageReducer';
import staticPageReducer from './pagesReducer';
import productsReducer from './productsReducer';

// Define the RootState type
export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers<any>({
  questions: questionReducer,
  userprofile: userProfileReducer,
  questionresult: resultReducer,
  questionfilter: filterReducer,
  questiondetail: questionDetailReducer,
  levelandpoints: levelReducer,
  avatarurls: avatarReducer,
  badgeurls: badgeReducer,
  levelImageUrl: levelImageReducer,
  pagesUrl:staticPageReducer,
  products:productsReducer,
});

export default rootReducer;
