import React, { useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
// import googleads980 from '../../../assets/img/google-ads-930.jpg';
// import googleads300 from '../../../assets/img/google-ads-300.png';
import earlogo from '../../../assets/img/earlogo.svg';
import clock from '../../../assets/img/clock.svg';
import india from '../../../assets/img/india.svg';
import iconplay from '../../../assets/img/iconplay.svg';
import Footer from '../../components/Footer/Footer';
import dashedplay from '../../../assets/img/dashedplay.svg';
import usa from '../../../assets/img/usa.svg';
import uk from '../../../assets/img/uk.svg';
import '../../scss/Quiz.scss';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import icon from '../../../assets/img/edit.svg';
import PlusIcon from '../../../assets/img/plus.png';

import { setResult } from '../../../../../../shared/store/src/lib/actions/questionresult';
import submitResult from '../../../../../../shared/Utils/SubmitResultApi/Api';
import ReportWord from '../../../../../../shared/Utils/ReportWordApi/Api';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Container,
  FormControl,
  Grid,
  Input,
  Box,
  Paper,
  Typography,
  SvgIcon,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWordData } from '../../Utils/firebase-services';
import { doc, getDoc } from '@firebase/firestore';
import { authen, db } from '../../firebase/firebase';
import { setUserProfile } from 'shared/store/src/lib/actions/userprofile';
import { setDetails } from 'shared/store/src/lib/actions/questiondetails';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import CustomReportWordModal from '../../components/CustomReportWordModal';
import RemoteErrorLog from '../../Utils/api';
import { environment } from 'apps/spellingheroweb/src/environments/environment';

const Quiz = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const savedState = useRef(
    state || JSON.parse(localStorage.getItem('quizState') || '{}')
  );
  const { navigated }: any = savedState.current || {};
  const [questionAnswerData, setQuestionAnswerData] = useState<{
    [key: string]: string;
  }>();

  const [currentWordDetail, setCurrentWordDetail] = useState<any>();
  let currentAudio: HTMLAudioElement | null = null;
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [description, setDescription] = useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const questions = useSelector((state: any) => state.questions);
  const questionDetailData = useSelector((state: any) => state.questiondetail);
  const [documents, setDocuments] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const scrollRef = useRef<any>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (state) {
      savedState.current = state;
      localStorage.setItem('quizState', JSON.stringify(state));
    }
  }, [state]);
  useEffect(() => {
    setIsLoading(true);
    if (navigated) {
      if (questionDetailData?.detail && questions?.[currentQuestionIndex]) {
        const value =
          questionDetailData?.detail?.[questions?.[currentQuestionIndex]];
        setCurrentWordDetail(value);
        setIsLoading(false);
      } else if (documents && documents[questions?.[currentQuestionIndex]]) {
        const value = documents[questions?.[currentQuestionIndex]];
        setCurrentWordDetail(value);
        setIsLoading(false);
      } else {
        fetchWordData(questions[currentQuestionIndex])
          .then((wordField: any) => {
            if (wordField) {
              const currentWordData = {
                [questions[currentQuestionIndex]]: wordField,
              };

              setCurrentWordDetail(wordField);
              setDocuments((prevState: any) => ({
                ...prevState, // Spread the previous state to maintain its values
                ...currentWordData, // Spread the new value to add it to the state
              }));
            }
          })
          .finally(() => setIsLoading(false));
      }
    } else {
      navigate('/home');
    }
  }, [currentQuestionIndex]);
  useEffect(() => {
    const qnaData = questions.reduce(
      (accumulator: any, currentValue: string) => {
        accumulator[currentValue] = '';
        return accumulator;
      },
      {}
    );
    setQuestionAnswerData(qnaData);
  }, [questions]);

  useEffect(() => {
    getCurrentQuestionData();
  }, [currentQuestionIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds < 59) {
        setSeconds(seconds + 1);
      } else {
        setSeconds(0);
        setMinutes(minutes + 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, minutes]);

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);

      // scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
      // const offset = 40; // Adjust to the desired scroll offset
      // const elementTop =
      //   scrollRef?.current?.getBoundingClientRect().top + window.pageYOffset;
      // const targetScrollPosition = elementTop - offset;

      // window.scrollTo({
      //   top: targetScrollPosition,
      //   behavior: 'smooth',
      // });
    }
  };

  const getUserProfile = async () => {
    try {
      const docRef = doc(
        db,
        'user_profile',
        authen?.currentUser?.uid ? authen?.currentUser?.uid : ''
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        dispatch(setUserProfile(data));
        return data;
      } else {
        return null;
      }
    } catch (error) {
      RemoteErrorLog(error, 'Quiz.tsx');

      return null;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    dispatch(setResult(questionAnswerData));
    if (!questionDetailData?.detail) {
      dispatch(setDetails(documents));
    }
    const resultInfo = {
      uid: authen.currentUser?.uid,
      attempt: questionAnswerData,
    };
    const apiResult = await submitResult(resultInfo, environment?.apiUrl);
    if (apiResult == 'Error') {
      setIsQuestionModalVisible(true);
      setApiErrorMessage(
        'We’re currently experiencing some issues. Please try again later.'
      );
      setIsLoading(false);
    } else if (!apiResult?.error_message) {
      await getUserProfile();
      setIsLoading(false);

      navigate('/result', {
        state: { apiResult: apiResult, documents: documents, navigated: true },
      });
    } else {
      setApiErrorMessage(apiResult?.error_message);
      setIsQuestionModalVisible(true);
      setIsLoading(false);
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }

    // const offset = 40; // Adjust to the desired scroll offset
    // const elementTop =
    //   scrollRef?.current?.getBoundingClientRect().top + window.pageYOffset;
    // const targetScrollPosition = elementTop - offset;

    // window.scrollTo({
    //   top: targetScrollPosition,
    //   behavior: 'smooth',
    // });
  };

  const getCurrentQuestionData = async () => {
    const currentWordName = questions[currentQuestionIndex];
    const wordDetails = documents?.[currentWordName];
    if (wordDetails) {
      setCurrentWordDetail(wordDetails);
    }
  };

  const handleChangeText = (text: string) => {
    let filteredText = '';
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z')) {
        filteredText += char;
      }
    }
    const wordKey = questions[currentQuestionIndex];
    setQuestionAnswerData({
      ...questionAnswerData,
      [wordKey]: filteredText.toLowerCase(),
    });
  };

  const base64ToBlob = (base64Data: string, contentType = 'audio/mp3') => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  // Function to play sound from base64 data
  const playSound = async (base64Sound: string,speed?: string) => {
    try {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
      const audioBlob = base64ToBlob(base64Sound);
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      if (speed === 'slow') {
        audio.playbackRate = 0.55;
      }
    currentAudio = audio;
      audio.onended = () => {
        URL.revokeObjectURL(audioUrl);
        currentAudio = null; 
      };

      audio.onerror = (error) => {
        URL.revokeObjectURL(audioUrl);
        currentAudio = null; 
      };

      await audio.play();
    } catch (error) {
      RemoteErrorLog(error, 'Quiz.tsx');
    }
  };

  // const playDynamicSound = useCallback(
  //   async (countryCode: number, playbackSpeed: any) => {
  //     const speedValue = Number(playbackSpeed);

  //     try {
  //       let base64Audio;
  //       switch (countryCode) {
  //         case 1:
  //           base64Audio = currentWordDetail?.audio_IN;
  //           break;
  //         case 2:
  //           base64Audio = currentWordDetail?.audio_US;
  //           break;
  //         case 3:
  //           base64Audio = currentWordDetail?.audio_UK;
  //           break;
  //         default:
  //           throw new Error('Invalid country code');
  //       }

  //       if (!base64Audio) {
  //         throw new Error('No audio data available');
  //       }

  //       const audioBlob = base64ToBlob(base64Audio);
  //       const audioUrl = URL.createObjectURL(audioBlob);
  //       const audio = new Audio(audioUrl);

  //       audio.playbackRate = speedValue;

  //       audio.onended = () => {
  //         URL.revokeObjectURL(audioUrl);
  //       };

  //       audio.onerror = (error) => {
  //         URL.revokeObjectURL(audioUrl);
  //       };

  //       await audio.play();
  //     } catch (error) {
  //       RemoteErrorLog(error, 'Quiz.tsx');
  //     }
  //   },
  //   [currentWordDetail]
  // );
  const reportAbuse = async () => {
    setIsModalVisible(false);
    const wordInfo = {
      uid: authen?.currentUser?.uid,
      word: questions[currentQuestionIndex] as string,
    };
    const response = await ReportWord(wordInfo, environment?.apiUrl);
    if (response == 'Error') {
      setIsQuestionModalVisible(true);
      setApiErrorMessage(
        'We’re currently experiencing some issues. Please try again later.'
      );
      // setIsLoading(false);
    } else if (!response?.error_message) {
      if (currentQuestionIndex + 1 === questions.length) {
        handleSubmit();
      } else {
        goToNextQuestion();
      }
    } else {
      setApiErrorMessage(response?.error_message);
      setIsQuestionModalVisible(true);
    }
  };
  const hideModal = () => {
    setIsModalVisible(false);
  };

  const hideNoQuestionModal = () => {
    setIsQuestionModalVisible(false);
  };

  return (
    <Box>
      <header>
        <Navbar />
      </header>
      <Header />

      <main className="page-bg overflow-hidden" ref={scrollRef} id="scrollRef">
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
          }}
        >
          {/* <Box className="googleAds top mx-auto">
            <img src={googleads980} alt="" />
          </Box> */}
          <section>
            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
              {/* <Grid item lg={3} xs={12} sx={{ textAlign: 'center' }} className='order-item-2' > */}
              {/* <Box className="googleAds top mx-auto ">
                <img src={googleads300} alt="" />
              </Box> */}
              {/* </Grid> */}

              <Grid item lg={6} xs={12}>
                {isLoading ? (
                  <Box
                    className="item-order-1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100vh',
                    }}
                  >
                    {' '}
                    <CircularProgress
                      size={56}
                      sx={{
                        color: 'primary.main',
                      }}
                    />{' '}
                  </Box>
                ) : (
                  <section className="quiz-step">
                    <Typography variant="h3">Let’s Spell!</Typography>
                    <Typography
                      className="dash-progress-quiz"
                      sx={{
                        textAlign: 'right',
                        marginRight: '39px',
                        marginTop: '5px',
                      }}
                    >
                      {currentQuestionIndex + 1}/{questions.length}
                    </Typography>
                    <Paper className="box-container quiz-container">
                      <Box className="round-quiz">
                        <ul>
                          {questions.map((ques: string, index: number) => (
                            <li
                              className={`${
                                questionAnswerData &&
                                questionAnswerData?.[ques].length
                                  ? ''
                                  : 'grey-box'
                              } `}
                            ></li>
                            // <li className="grey-box"></li>
                          ))}
                        </ul>
                      </Box>

                      <Box
                        className="d-flex justify-content-between  mt-3"
                        sx={{ alignItems: 'flex-start' }}
                      >
                        <Box>
                          <Typography
                            variant="body1"
                            className="font-400 text-blackish"
                          >
                            <img
                              width={30}
                              className="me-2 align-middle"
                              src={earlogo}
                              alt=" "
                            />
                            Click a button below to listen to the Indian, USA or
                            UK accent.
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              display: 'flex',
                              flexWrap: 'nowrap',
                              alignItems: 'center',
                            }}
                          >
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                            <img
                              width={30}
                              className="ms-2 align-middle"
                              src={clock}
                              alt=""
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="mt-4 accent-grid-type">
                        <Box
                          className="accent-box cursor-pointer"
                          onClick={() => {
                            playSound(currentWordDetail?.audio_IN);
                          }}
                        >
                          <Box>
                            <Typography
                              variant="body1"
                              className="font-600 text-light"
                            >
                              India
                            </Typography>
                          </Box>
                          <Box className="play-inside-img">
                            <img src={india} alt="" className="flag" />
                            <Button style={{ padding: 0, minWidth: 'auto' }}>
                              <img
                                src={iconplay}
                                alt="image"
                                className="btn-speaker"
                              />
                            </Button>
                          </Box>
                        </Box>
                        <Box
                          className="accent-box cursor-pointer"
                          onClick={() => {
                            playSound(currentWordDetail?.audio_US);
                          }}
                        >
                          <Box>
                            <Typography
                              variant="body1"
                              className="font-600 text-light"
                            >
                              USA
                            </Typography>
                          </Box>
                          <Box className="play-inside-img">
                            <img src={usa} alt="" className="flag" />
                            <Button style={{ padding: 0, minWidth: 'auto' }}>
                              <img
                                src={iconplay}
                                alt="image"
                                className="btn-speaker"
                              />
                            </Button>
                          </Box>
                        </Box>
                        <Box
                          className="accent-box cursor-pointer"
                          onClick={() => {
                            playSound(currentWordDetail?.audio_UK);
                          }}
                        >
                          <Box>
                            <Typography
                              variant="body1"
                              className="font-600 text-light"
                            >
                              UK
                            </Typography>
                          </Box>
                          <Box className="play-inside-img">
                            <img src={uk} alt="" className="flag" />

                            <Button style={{ padding: 0, minWidth: 'auto' }}>
                              <img
                                src={iconplay}
                                alt="image"
                                className="btn-speaker"
                              />
                            </Button>
                          </Box>
                        </Box>
                        <Box
                          className="accent-box cursor-pointer"
                          onClick={() => {
                            const speed = 'slow';
                             playSound(currentWordDetail?.audio_IN,speed);
                          }}
                        >
                          <Box>
                            <Typography
                              variant="body1"
                              className="font-600 text-light"
                            >
                              India
                            </Typography>
                          </Box>
                          <Box className="play-inside-img">
                            <img src={india} alt="" className="flag" />
                            <Button style={{ padding: 0, minWidth: 'auto' }}>
                              <img
                                src={dashedplay}
                                alt="Play"
                                className="btn-slow"
                              />
                            </Button>
                          </Box>
                          <Typography
                            variant="body2"
                            className="text-right text-light font-600 slow-speed"
                          >
                            Slow Speed
                          </Typography>
                        </Box>
                        <Box
                          className="accent-box cursor-pointer"
                          onClick={() => {
                            const speed = 'slow';
                            playSound(currentWordDetail?.audio_US,speed);
                          }}
                        >
                          <Box>
                            <Typography
                              variant="body1"
                              className="font-600 text-light"
                            >
                              USA
                            </Typography>
                          </Box>
                          <Box className="play-inside-img">
                            <img src={usa} alt="" className="flag" />
                            <Button style={{ padding: 0, minWidth: 'auto' }}>
                              <img
                                src={dashedplay}
                                alt="image"
                                className="btn-slow"
                              />
                            </Button>
                          </Box>
                          <Typography
                            variant="body2"
                            className="text-right text-light font-600 slow-speed"
                          >
                            Slow Speed
                          </Typography>
                        </Box>
                        <Box
                          className="accent-box cursor-pointer"
                          onClick={() => {
                            const speed = 'slow';
                            playSound(currentWordDetail?.audio_UK,speed);
                          }}
                        >
                          <Box>
                            <Typography
                              variant="body1"
                              className="font-600 text-light"
                            >
                              UK
                            </Typography>
                          </Box>
                          <Box className="play-inside-img">
                            <img src={uk} alt="" className="flag" />
                            <Button style={{ padding: 0, minWidth: 'auto' }}>
                              <img
                                src={dashedplay}
                                alt="image"
                                className="btn-slow"
                              />
                            </Button>
                          </Box>
                          <Typography
                            variant="body2"
                            className="text-right text-light font-600 slow-speed"
                          >
                            Slow Speed
                          </Typography>
                        </Box>
                      </Box>

                      <Accordion
                        // defaultExpanded
                        expanded={description}
                        onClick={() => setDescription(!description)}
                        className="part-speech-card"
                      >
                        <AccordionSummary
                          expandIcon={
                            description ? (
                              <SvgIcon className="part-speech-svg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <circle
                                    cx="15"
                                    cy="15"
                                    r="15"
                                    fill="#5FCD6A"
                                    fill-opacity="0.5"
                                  />
                                  <path
                                    d="M9.16797 15H20.8346"
                                    stroke="#2A9235"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </SvgIcon>
                            ) : (
                              <img src={PlusIcon} />
                            )
                          }
                        >
                          <Typography
                            variant="h4"
                            className="mb-0 text-para-black  font-500 part-of-speech-text"
                          >
                            Part of Speech:
                            <span className="font-400 ps-2">
                              <i> {currentWordDetail?.part_of_speech}</i>
                            </span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: '0px 16px 5px !important' }}
                        >
                          <Typography variant="h4" className="font-22 font-500">
                            Description:
                          </Typography>
                          <Typography
                            variant="body1"
                            className="text-para-black"
                          >
                            {currentWordDetail?.def}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Box className="quiz-padding ">
                        <Typography
                          variant="h4"
                          className="text-para-black  font-500 part-of-speech-text"
                          sx={{ paddingBottom: '12px' }}
                        >
                          Enter the spelling of the word you heard.
                          <img src={icon} className="" alt="" />
                        </Typography>
                        <FormControl fullWidth className="mb-3 input-name">
                          <Input
                            className="input-border-btm"
                            placeholder="Your answer"
                            value={
                              questionAnswerData?.[
                                questions[currentQuestionIndex]
                              ]
                            }
                            onChange={(e) => handleChangeText(e.target.value)}
                            inputProps={{
                              autoComplete: 'off', // Prevent password suggestion
                              spellCheck: 'false', // Disables spell check
                              autoCorrect: 'off', // Disables autocorrect
                              autoCapitalize: 'none', // Prevent auto-capitalization
                              type: 'text', // Set input type to text
                              inputMode: 'text',
                              'aria-autocomplete': 'none', // Disable autocomplete on mobile devices
                              importantForAutofill: 'no', // Prevent autofill
                              contextMenuHidden: true, // Hide context menu on right-click or long-press
                              keyboardType: 'visible-password', // Similar to `keyboardType` in React Native
                            }}
                            disableUnderline={true}
                          />
                        </FormControl>
                        <Box
                          className="text-end anchor-line"
                          onClick={() => setIsModalVisible(true)}
                        >
                          <Typography
                            className=" cursor-pointer"
                            sx={{
                              color: '#CF232A',
                              textDecoration: 'underline',
                              fontFamily: 'roboto',
                              fontWeight: '500',
                            }}
                          >
                            Report an Abusive word
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        className="d-flex  gap-3   btn-combo "
                        sx={{ paddingBottom: '20px', paddingTop: '20px' }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={
                            <ArrowBackIosIcon className="custom-icon-class" />
                          }
                          className="btn-quiz-next btn-previous-quiz"
                          onClick={goToPreviousQuestion}
                        >
                          Previous
                        </Button>
                        {currentQuestionIndex + 1 !== questions.length && (
                          <Button
                            variant="contained"
                            className="btn btn-primary btn-quiz-next "
                            endIcon={<NavigateNextIcon />}
                            onClick={goToNextQuestion}
                          >
                            Next
                          </Button>
                        )}
                        {currentQuestionIndex + 1 === questions.length && (
                          <Button
                            variant="contained"
                            className="btn btn-primary btn-quiz-next "
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        )}
                      </Box>
                    </Paper>
                    <CustomNoResponseModal
                      isModalVisible={isQuestionModalVisible}
                      onPress={hideNoQuestionModal}
                      buttonText="OK"
                      modalTitle="Oops!"
                      text={apiErrorMessage}
                    />
                    <CustomReportWordModal
                      isModalVisible={isModalVisible}
                      onPressbutton1={hideModal}
                      onPressbutton2={reportAbuse}
                      buttonText1="No"
                      buttonText2="Yes"
                      modalTitle="Report Word"
                      text="Are you sure you want to report this word?"
                    />
                  </section>
                )}
              </Grid>
              {/* <Grid
              item
              lg={3}
              xs={12}
              className="pb-3 "
          
              sx={{ textAlign: 'center' }}
            > */}
              {/* <Box className="googleAds top mx-auto ">
                <img src={googleads300} alt="" />
              </Box> */}
              {/* </Grid> */}
            </Grid>
          </section>
        </Container>
      </main>
      <Footer />
    </Box>
  );
};

export default Quiz;
