import axios from "axios";
const GetLevelAndPoints = async (apiUrl:string) => {
    try {        
      const response = await axios.post(
        `${apiUrl}getLevelPointsMapping`,  
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          timeout:30000
        },
      );
      const apiData = response.data;      
      return apiData;
    } catch (error) {
      return 'Error'
    }
  };
export default GetLevelAndPoints;  