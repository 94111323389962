import React from "react";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }: any) => {
  const userData = localStorage.getItem("userData") ?? "{}";
  const data =JSON.parse(userData )
  if (JSON.parse(userData )?.isAnonymous==true || JSON.parse(userData )?.isAnonymous==null ) return <Navigate to="/login" />;
  return children;
};

export default RequireAuth;
