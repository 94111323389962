import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/Navbar/Navbar';
import { Box, CircularProgress } from '@mui/material';
import '../../scss/StaticPages.scss';
import Footer from '../../components/Footer/Footer';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../../firebase/firebase';
import { setStaticPages } from 'shared/store/src/lib/actions/staticPages';
import RemoteErrorLog from '../../Utils/api';
import { Helmet } from 'react-helmet';
const PrivacyPolicy = () => {
  const pagesUrl = useSelector((state: any) => state.pagesUrl);
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad: React.ReactEventHandler<HTMLIFrameElement> = () => {
    setIsLoading(false);
  };
  const dispatch = useDispatch();

  const fetchPages = async () => {
    if (!pagesUrl.length) {
      try {
        try {
          const storageRef = await ref(storage, 'pages');
          const result_1 = await listAll(storageRef);
          const urls = await Promise.all(
            result_1.items.map((pages) => getDownloadURL(pages))
          );
          dispatch(setStaticPages(urls));
        } catch (e) {
          RemoteErrorLog(e, 'PrivacyPolicy.tsx');
        }
      } finally {
      }
    }
  };
  useEffect(() => {
    fetchPages();
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          Best App to Improve Spelling-Spelling Hero | Privacy Policy
        </title>
        <meta
          name="description"
          content="This is the privacy policy page for Spelling Hero app, which is one of the best apps to improve spelling for adults & kids through the help of spelling games."
        />
        <link
          rel="canonical"
          href="https://spellinghero.app/privacy-policy"
        ></link>
      </Helmet>
      <Navbar currentPage="privacypolicy" />

      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          {' '}
          <CircularProgress
            size={56}
            sx={{
              color: 'primary.main',
            }}
          />{' '}
        </Box>
      )}
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <div className="privacy-heading">
          <h1
            style={{
              fontSize: 40,
              color: '#333',
              textAlign: 'center',
              letterSpacing: 5,
              fontFamily: 'roboto',
              paddingTop: '20px',
            }}
          >
            Privacy Policy
          </h1>
        </div>
        <div
          className="iframe-container"
          style={{
            overflow: 'hidden',
          }}
        >
          <iframe
            src={pagesUrl[1]}
            onLoad={handleLoad}
            title="Full Screen Iframe"
            style={{
              width: '100%',
              height: '100%',
              minHeight: '100vh',
              border: 'none',
            }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
