import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import mailicon from '../../assets/img/email-icon-dn.svg';

const CustomModal = ({
  isModalVisible,
  onPress,
  buttonText,
  text,
  modalTitle,
  icon,
}: any) => {
  return (
    <Dialog
      open={isModalVisible}
      onClose={onPress}
      aria-labelledby="email-confirm-dialog"
    >
      <DialogTitle id="email-confirm-dialog" style={{ textAlign: 'center' }}>
        {icon && (
          <img
            src={mailicon}
            className="mx-auto d-block mb-2"
            width={60}
            alt="Mail Icon"
          />
        )}
        {modalTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center' }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onPress} color="primary" variant="contained">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
