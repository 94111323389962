import { updateProfileType } from "../../types/globaltypes";
import axios from "axios";

const updateUserProfile = async (data: updateProfileType , apiUrl:string) => {
    try {
      const response = await axios.post(
        `${apiUrl}updateProfile`,    
        data,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          timeout:30000
        },
      );
      const apiData = response;
      return apiData.data;
    } catch (error) {
       return 'Error'
    }
  };

  export default updateUserProfile;