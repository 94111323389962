// Import the functions you need from the SDKs you need
import { initializeApp} from "firebase/app";
import {getAuth , GoogleAuthProvider,OAuthProvider, signInWithPopup} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import 'firebase/storage';
import { getStorage } from "firebase/storage";
import { environment } from '../../environments/environment';
import { getFunctions } from 'firebase/functions';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional



const firebaseConfig = {
  apiKey: environment?.firebase?.apiKey,
  authDomain:  environment?.firebase?.authDomain,
  projectId:  environment?.firebase?.projectId,
  storageBucket:  environment?.firebase?.storageBucket,
  messagingSenderId: environment?.firebase?.messagingSenderId,
  appId: environment?.firebase?.appId,
  measurementId: environment?.firebase?.measurementId
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authen = getAuth(app);
const provider =new GoogleAuthProvider();
const providerApple = new OAuthProvider('apple.com');
export const storage = getStorage();
export const db = getFirestore(app);
export const functions = getFunctions(app,environment?.region);
export {app , provider  , providerApple };