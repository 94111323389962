import React, { useEffect, useState } from 'react';
import './Header.scss';
// import '../Slider_bar/Slider_bar.scss';
import '../../scss/CurrentSlider.scss';

import Round_cancle from '../../../assets/img/round-cancel.svg';
import ProgressBar from '../Progressbar/ProgressBar';
import { useSelector } from 'react-redux';
import CircleTick from '../../../assets/img/header-circletick.png';
import Headerchecked from '../../../assets/img/header-checked.png';
import Headerincorrect from '../../../assets/img/header-incorrect.png';
import { authen } from '../../firebase/firebase';
import { Container, Typography, Box } from '@mui/material';

export default function Header({ currentPage }: any) {
  const profile = useSelector((state: any) => state.userprofile);
  const [attainedBadgeUrl, setAttainedBadgeUrl] = useState('');
  const [attainedLevelUrl, setAttainedLevelUrl] = useState('');

  const badgesUrl = useSelector((state: any) => state.badgeurls);
  const level = useSelector((state: any) => state.levelandpoints);
  const levelsImageUrl = useSelector((state: any) => state.levelImageUrl);

  const [levelTab, setLevelTab] = useState(false);
  const [wordTab, setWordTab] = useState(false);
  useEffect(() => {
    if (
      (badgesUrl.detail &&
        profile?.user?.phonogram &&
        attainedBadgeUrl !== '') === true
    ) {
    }

    const imageUrl =
      levelsImageUrl[profile?.user?.metrics?.levels_acquired?.length - 1] || ''; // Default image path if no URL is found

    setAttainedLevelUrl(imageUrl);
    badgesUrl.detail &&
      setAttainedBadgeUrl(
        `${
          Object.values(badgesUrl.detail)[
            profile.user?.metrics?.badges_attained?.length - 1
          ]
        }`
      );
  }, [badgesUrl.detail, profile, attainedBadgeUrl, levelsImageUrl]);
  return (
    <>
      <header className="headerBanner">
        <main>
          <Container
            sx={{
              maxWidth: { xs: '100%', sm: '95%', md: '90%' },
            }}
          >
            <Box className="user-score-points" sx={{ display: 'grid' }}>
              <section>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '12px',
                  }}
                >
                  {/* <img src={Headercurrent} alt="badge" /> */}
                  {attainedLevelUrl !== '' &&
                    !authen.currentUser?.isAnonymous && (
                      <img src={attainedLevelUrl} alt="Badges Earned" />
                    )}
                  <Box>
                    <Typography
                      variant="h6"
                      className="font-500 current-level-header"
                      sx={{
                        fontFamily: 'Roboto',
                        color: '#ffffff',
                        fontSize: '20px',
                        marginTop: '-6px !important',
                      }}
                    >
                      Current Level:{' '}
                      <Typography
                        component={'span'}
                        className="font-500"
                        style={{ fontStyle: 'italic' }}
                        sx={{
                          fontFamily: 'Roboto',
                          color: '#ffffff',
                        }}
                      >
                        {' '}
                        {!authen.currentUser?.isAnonymous
                          ? profile.user?.metrics?.name_level_curr
                          : '--'}
                      </Typography>
                    </Typography>
                    <Box className="slider-current-level">
                      <Box className="customprogress">
                        {!authen.currentUser?.isAnonymous && (
                          <Typography className="custmPrgsTxtunfill">
                            {(profile.user?.metrics?.next_level_points_scored /
                              profile.user?.metrics?.next_level_points_total) *
                              100 <=
                              70 &&
                              `${profile.user?.metrics?.next_level_points_total} Points`}
                          </Typography>
                        )}

                        <Box
                          className="innerFill"
                          sx={{
                            width: !authen.currentUser?.isAnonymous
                              ? `${
                                  (profile.user?.metrics
                                    ?.next_level_points_scored /
                                    profile.user?.metrics
                                      ?.next_level_points_total) *
                                    100 <=
                                  4
                                    ? 4
                                    : (profile.user?.metrics
                                        ?.next_level_points_scored /
                                        profile.user?.metrics
                                          ?.next_level_points_total) *
                                      100
                                }%`
                              : '4%',
                          }}
                        >
                          {!authen.currentUser?.isAnonymous && (
                            <Typography className="custmPrgsTxt">
                              {(profile.user?.metrics
                                ?.next_level_points_scored /
                                profile.user?.metrics
                                  ?.next_level_points_total) *
                                100 >=
                                30 &&
                                `${profile.user?.metrics?.next_level_points_scored} Points`}
                            </Typography>
                          )}
                          {/* <Box className="customtooltip">hello
                      </Box> */}
                        </Box>
                      </Box>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Roboto',
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '300',
                      }}
                    >
                      You need just{' '}
                      {!authen.currentUser?.isAnonymous
                        ? profile.user?.metrics?.next_level_points_remaining
                        : '--'}{' '}
                      more points to reach the next level!
                    </Typography>
                  </Box>
                </Box>
              </section>
              <section>
                <Box
                  className="score-point"
                  sx={{ display: 'flex', textAlign: 'center' }}
                >
                  <Box className="title top">Badges Earned</Box>
                  <Box className="badge-img">
                    {profile.user?.metrics?.badges_attained !== undefined &&
                      !authen.currentUser?.isAnonymous && (
                        <img src={attainedBadgeUrl} alt="Badges Earned" />
                      )}

                    {/* <img src={HomeBadge} alt='badge'/> */}
                  </Box>
                  {authen.currentUser?.isAnonymous ? (
                    <Box className="title bottom">--</Box>
                  ) : profile.user?.metrics?.badges_attained === undefined ? (
                    <Box className="title bottom">0</Box>
                  ) : (
                    <Box className="title bottom">
                      {' '}
                      {profile.user?.metrics?.badges_attained?.length}
                    </Box>
                  )}
                </Box>
              </section>
              <section>
                <Box
                  className="score-point"
                  sx={{ display: 'flex', textAlign: 'center' }}
                >
                  <Box className="title top">Attempted Words</Box>
                  <Box className="badge-img">
                    <img src={CircleTick} alt="Current Level" />
                  </Box>
                  <Box className="title bottom">
                    {!authen.currentUser?.isAnonymous
                      ? profile.user?.metrics?.words_num_total
                      : '--'}
                  </Box>
                </Box>
              </section>
              <section>
                <Box
                  className="score-point"
                  sx={{ display: 'flex', textAlign: 'center' }}
                >
                  <Box className="title top">Correct Words</Box>
                  <Box className="badge-img">
                    <img src={Headerchecked} alt="Current Level" />
                  </Box>
                  <Box className="title bottom">
                    {!authen.currentUser?.isAnonymous
                      ? profile.user?.metrics?.words_num_correct
                      : '--'}
                  </Box>
                </Box>
              </section>
              <section>
                <Box
                  className="score-point"
                  sx={{ display: 'flex', textAlign: 'center' }}
                >
                  <Box className="title top">Incorrect Words</Box>
                  <Box className="badge-img">
                    <img src={Headerincorrect} alt="incorrect" />
                  </Box>
                  <Box className="title bottom">
                    {!authen.currentUser?.isAnonymous
                      ? profile.user?.metrics?.words_num_incorrect
                      : '--'}
                  </Box>
                </Box>
              </section>
            </Box>
          </Container>
        </main>
      </header>

      {/* responsive mobile */}
      <main className={` ${currentPage == 'home' ? '' : ' margin-t-50'}`}>
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '90%' },
          }}
        >
          <header
            // style={{ display: 'flex', gap: '20px', alignItems: 'center' }}
            className="header-quiz"
          >
            <section>
              {!wordTab && !levelTab && (
                <Box
                  className={`mobile-responsive-quiz ${
                    currentPage == 'home' ? '' : ' greenbar-no-header '
                  }`}
                >
                  <Box className="header-quiz-response ">
                    <Box
                      className="cursor-pointer"
                      onClick={() => setLevelTab(true)}
                    >
                      <Typography
                        variant="h6"
                        className="font-600"
                        sx={{
                          fontFamily: 'poppins',
                          color: 'white',
                          fontSize: '18px',
                          marginBottom: '14px',
                        }}
                      >
                        Level
                      </Typography>

                      <Box>
                        <Box className="slider-current-level">
                          {/* <Box
                        className="custmProgess"
                        sx={{
                          height: '6px',
                          flexDirection: 'row',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                      >
                        <Box
                          className="innerFill"
                          sx={{
                            width: !authen.currentUser?.isAnonymous
                              ? `${
                                  (profile.user?.metrics
                                    ?.next_level_points_scored /
                                    profile.user?.metrics
                                      ?.next_level_points_total) *
                                    100 <=
                                  9
                                    ? 9
                                    : (profile.user?.metrics
                                        ?.next_level_points_scored /
                                        profile.user?.metrics
                                          ?.next_level_points_tota) *
                                      100
                                }%`
                              : '0%',
                            height: '6px',
                          }}
                        >
                          {' '}
                          <Box
                            sx={{
                              flex: 1,
                              justifyContent: 'center',
                              // alignItems: 'flex-end',
                            }}
                          >
                            {' '}
                            <img
                              src={LevelStar}
                              alt="Star"
                              width={13}
                              height={13}
                            />
                          </Box>
                        </Box>
                      </Box> */}
                          {/* <SliderBar
                        percentage={
                          !authen.currentUser?.isAnonymous
                            ? (profile.user?.metrics?.next_level_points_scored /
                                profile.user?.metrics
                                  ?.next_level_points_total) *
                              100
                            : 0
                        }
                      /> */}
                          <Box>
                            <Box className="slider-current-level">
                              <Box
                                className="customprogress "
                                sx={{
                                  height: '6px',
                                  width: 'unset',
                                  margin: 'unset',
                                }}
                              >
                                <Box
                                  className="innerFill sliderfill"
                                  sx={{
                                    width: !authen.currentUser?.isAnonymous
                                      ? `${
                                          (profile.user?.metrics
                                            ?.next_level_points_scored /
                                            profile.user?.metrics
                                              ?.next_level_points_total) *
                                            100 <=
                                          5
                                            ? 5
                                            : (profile.user?.metrics
                                                ?.next_level_points_scored /
                                                profile.user?.metrics
                                                  ?.next_level_points_total) *
                                              100
                                        }%`
                                      : '5%',
                                    height: '6px',
                                  }}
                                ></Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: 'poppins',
                          color: 'white',
                          fontSize: '11px',
                          fontWeight: '600',
                          marginTop: '14px !important',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {!authen.currentUser?.isAnonymous
                          ? profile.user?.metrics?.name_level_curr
                          : '--'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        className="font-600"
                        sx={{
                          fontFamily: 'poppins',
                          color: 'white',
                          fontSize: '18px',
                          marginBottom: `${
                            !authen.currentUser?.isAnonymous &&
                            profile.user?.metrics?.badges_attained !== undefined
                              ? '1px'
                              : '14px'
                          }!important`,
                        }}
                      >
                        Badge
                      </Typography>
                      {!authen.currentUser?.isAnonymous &&
                      profile.user?.metrics?.badges_attained !== undefined ? (
                        <img
                          src={attainedBadgeUrl}
                          alt="badge-image"
                          width="38px"
                        />
                      ) : (
                        <Typography
                          sx={{
                            fontFamily: 'poppins',
                            color: 'white',
                            fontSize: '11px',
                            fontWeight: '600',
                            lineHeight: '9px !important',
                          }}
                        >
                          --
                        </Typography>
                      )}
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: 'poppins',
                          color: 'white',
                          fontSize: '11px',
                          fontWeight: '600',
                          marginTop: `${
                            !authen.currentUser?.isAnonymous &&
                            profile.user?.metrics?.badges_attained !== undefined
                              ? '1px'
                              : '14px'
                          }!important`,
                        }}
                      >
                        {authen.currentUser?.isAnonymous
                          ? '--'
                          : profile.user?.metrics?.badges_attained === undefined
                          ? 0
                          : profile.user?.metrics?.badges_attained?.length}
                      </Typography>
                    </Box>
                    <Box
                      className="cursor-pointer"
                      onClick={() => setWordTab(true)}
                    >
                      <Typography
                        variant="h6"
                        className="font-600"
                        sx={{
                          fontFamily: 'poppins',
                          color: 'white',
                          fontSize: '18px',
                          marginBottom: '14px !important',
                        }}
                      >
                        Words
                      </Typography>

                      <ProgressBar
                        correctWordsPercentage={
                          !authen.currentUser?.isAnonymous
                            ? (Number(
                                profile.user?.metrics?.words_num_correct
                              ) /
                                Number(
                                  profile.user?.metrics?.words_num_total
                                )) *
                              100
                            : 0
                        }
                      />

                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: 'poppins',
                          color: 'white',
                          fontSize: '11px',
                          fontWeight: '600',
                          marginTop: '14px !important',
                        }}
                      >
                        {!authen.currentUser?.isAnonymous
                          ? profile.user?.metrics?.words_num_total
                          : '--'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              {levelTab && (
                <Box className="current-level" sx={{ marginTop: 10 }}>
                  <img
                    src={Round_cancle}
                    alt="round-cancel"
                    className="img-current-level cursor-pointe"
                    onClick={() => setLevelTab(false)}
                  />{' '}
                  <Box>
                    <Typography
                      variant="h6"
                      className="font-500"
                      sx={{
                        fontFamily: 'poppins',
                        color: '#333333',
                        fontSize: '18px',
                      }}
                    >
                      Current Level:{' '}
                      {!authen.currentUser?.isAnonymous
                        ? profile.user?.metrics?.name_level_curr
                        : '--'}
                    </Typography>
                    <Box className="slider-current-level">
                      <Box className="customprogress" sx={{ width: 'unset' }}>
                        {!authen.currentUser?.isAnonymous && (
                          <Typography className="custmPrgsTxtunfill">
                            {(profile.user?.metrics?.next_level_points_scored /
                              profile.user?.metrics?.next_level_points_total) *
                              100 <=
                              70 &&
                              `${profile.user?.metrics?.next_level_points_total} Points`}
                          </Typography>
                        )}

                        <Box
                          className="innerFill"
                          sx={{
                            width: !authen.currentUser?.isAnonymous
                              ? `${
                                  (profile.user?.metrics
                                    ?.next_level_points_scored /
                                    profile.user?.metrics
                                      ?.next_level_points_total) *
                                    100 <=
                                  5
                                    ? 5
                                    : (profile.user?.metrics
                                        ?.next_level_points_scored /
                                        profile.user?.metrics
                                          ?.next_level_points_total) *
                                      100
                                }%`
                              : '5%',
                          }}
                        >
                          {!authen.currentUser?.isAnonymous && (
                            <Typography className="custmPrgsTxt">
                              {(profile.user?.metrics
                                ?.next_level_points_scored /
                                profile.user?.metrics
                                  ?.next_level_points_total) *
                                100 >=
                                22 &&
                                `${profile.user?.metrics?.next_level_points_scored} Points`}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'poppins',
                        color: '#2A9235',
                        fontSize: '12px',
                        fontWeight: '600',
                      }}
                    >
                      You need{' '}
                      {!authen.currentUser?.isAnonymous
                        ? profile.user?.metrics?.next_level_points_remaining
                        : '--'}{' '}
                      more points to reach the next level!
                    </Typography>
                  </Box>
                  {/* <Box>
                <Typography
                  variant="h6"
                  className="font-500"
                  sx={{
                    fontFamily: 'poppins',
                    color: '#333333',
                    fontSize: '18px',
                  }}
                >
                  Current Level:{' '}
                  {!authen.currentUser?.isAnonymous
                    ? profile.user?.metrics?.name_level_curr
                    : '--'}
                </Typography>
                <Box className="slider-current-level">
                  <Box className="custmProgess ">
                    <Box
                      className="innerFill"
                      style={{
                        width: !authen.currentUser?.isAnonymous
                          ? `${
                              (profile.user?.metrics?.next_level_points_scored /
                                profile.user?.metrics
                                  ?.next_level_points_total) *
                                100 <=
                              9
                                ? 9
                                : (profile.user?.metrics
                                    ?.next_level_points_scored /
                                    profile.user?.metrics
                                      ?.next_level_points_tota) *
                                  100
                            }%`
                          : '0%',
                      }}
                    >
                      {!authen.currentUser?.isAnonymous && (
                        <Typography className="custmPrgsTxt">
                          {(profile.user?.metrics?.next_level_points_scored /
                            profile.user?.metrics?.next_level_points_total) *
                            100 >=
                            22 &&
                            `${profile.user?.metrics?.next_level_points_scored} Points`}
                        </Typography>
                      )}

                      <img src={LevelStar} alt="Star" width={20} height={20} />
                    </Box>

                    {!authen.currentUser?.isAnonymous && (
                      <Typography className="custmPrgsTxtunfill">
                        {(profile.user?.metrics?.next_level_points_scored /
                          profile.user?.metrics?.next_level_points_total) *
                          100 <=
                          87 &&
                          `${profile.user?.metrics?.next_level_points_total} Points`}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'poppins',
                    color: '#2A9235',
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  You need{' '}
                  {!authen.currentUser?.isAnonymous
                    ? profile.user?.metrics?.next_level_points_remaining
                    : '--'}{' '}
                  more points to reach the next level!
                </Typography>
              </Box> */}
                </Box>
              )}

              {wordTab && (
                <Box
                  className="current-level current-words-level  "
                  // sx={{ display: 'none' }}
                  sx={{ marginTop: 10 }}
                >
                  <Box className="current-words">
                    <img
                      src={Round_cancle}
                      alt="round-cancel"
                      className="words-img cursor-pointer"
                      onClick={() => setWordTab(false)}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        className="font-600"
                        sx={{
                          fontFamily: 'poppins',
                          color: '#333333',
                          fontSize: '12px',
                          maxWidth: '10ch',
                        }}
                      >
                        Attempted Words
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: 'poppins',
                          color: '#2A9235',
                          fontSize: '32px',
                          fontWeight: '600',
                        }}
                      >
                        {!authen.currentUser?.isAnonymous
                          ? profile.user?.metrics?.words_num_total
                          : '--'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        className="font-600"
                        sx={{
                          fontFamily: 'poppins',
                          color: '#333333',
                          fontSize: '12px',
                          maxWidth: '10ch',
                        }}
                      >
                        Correct Words
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: 'poppins',
                          color: '#2A9235',
                          fontSize: '32px',
                          fontWeight: '600',
                        }}
                      >
                        {!authen.currentUser?.isAnonymous
                          ? profile.user?.metrics?.words_num_correct
                          : '--'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        className="font-600"
                        sx={{
                          fontFamily: 'poppins',
                          color: '#333333',
                          fontSize: '12px',
                          maxWidth: '10ch',
                        }}
                      >
                        Incorrect Words
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: 'poppins',
                          color: '#2A9235',
                          fontSize: '32px',
                          fontWeight: '600',
                        }}
                      >
                        {!authen.currentUser?.isAnonymous
                          ? profile.user?.metrics?.words_num_incorrect
                          : '--'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </section>
          </header>
        </Container>
      </main>
    </>
  );
}
