import { addDoc, collection, doc, onSnapshot } from "@firebase/firestore";
import getStripe from "./initializeStripe";
import { db } from "../firebase/firebase";
import { environment } from "../../environments/environment";
import RemoteErrorLog from "../Utils/api";

export async function createCheckoutSession(uid: string , priceId:string) {

  try{
  const userDocRef = doc(db, 'membership', uid);

  const checkoutSessionsCollectionRef = collection(userDocRef, 'checkout_sessions');
  
  const checkoutSessionRef = await addDoc(checkoutSessionsCollectionRef, {
    price: priceId, 
    success_url: environment?.baseUrl+"profile/3",
    cancel_url: environment?.baseUrl+"profile/3",
  });

  onSnapshot(checkoutSessionRef, async (snap) => {
    const data = snap.data();
    const sessionId = data?.sessionId;
    if (sessionId) {

      const stripe = await getStripe();

      stripe?.redirectToCheckout({ sessionId });
return { success: true, message: "Redirected to Stripe checkout" };
    }

    return { success: true, message: "Checkout session created successfully" };
  });
}
catch(error)
{
  RemoteErrorLog(error,"createCheckoutSession.ts")
  return { success: false, message: "Failed to create checkout session", error };
}
}
