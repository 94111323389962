import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from '@mui/material';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import '../../scss/ChangePassword.scss';
import TotalAttempted from '../../../assets/img/total-attempted.png';
import CheckedWords from '../../../assets/img/checked-words.png';
import OopsWord from '../../../assets/img/header-incorrect.png';
import CircularProgress from '../../components/CircularProgress/CircularProgress';
import googleads300 from '../../../assets/img/google-ads-300.png';
import { useDispatch, useSelector } from 'react-redux';
import { authen, db } from '../../firebase/firebase';
import Footer from '../../components/Footer/Footer';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from '@firebase/firestore';
import { setUserProfile } from 'shared/store/src/lib/actions/userprofile';
import RemoteErrorLog from '../../Utils/api';
// import AdSenseAd from '../../components/AdSenseAd';

export default function Score() {
  const profile = useSelector((state: any) => state.userprofile);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [user, setUser] = useState<any>();

  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (user?.uid) {
      getUserProfile(user?.uid);
    } else if (authen?.currentUser?.uid) {
      getUserProfile(authen?.currentUser?.uid);
    }
  }, [user, authen]);
  const getUserProfile = async (uid: string) => {
    try {
      const docRef = doc(db, 'user_profile', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        dispatch(setUserProfile(data));
        return data;
      } else {
        return null;
      }
    } catch (error) {
      RemoteErrorLog(error, 'MyProgress.tsx');

      return null;
    }
  };
  return (
    <>
      <Navbar />
      <Header />

      <main className="page-bg overflow-hidden change-psd-page">
        {/* <Box
          className="googleAds top mx-auto"
          sx={{ textAlign: 'center', paddingBottom: '30px' }}
        >
          <img src={googleads980} alt="Google Ads 980" />
        </Box> */}
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              lg={3}
              className="pt-0 order-item-2"
              sx={{ textAlign: 'center' }}
            >
              <Box className="googleAds top mx-auto">
                {/* <img src={googleads300} alt="Google Ads 300" /> */}
                {/* <AdSenseAd /> */}
                {/* <ins
                  className="adsbygoogle"
                  style={{ display: 'block' }}
                  data-ad-client="ca-pub-4782637656830388"
                  data-ad-slot="6165435869"
                  data-ad-format="auto"
                  data-full-width-responsive="true"
                ></ins> */}
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <section className="quiz-step change-psd ">
                <Typography
                  variant="h4"
                  gutterBottom
                  padding="8px 15px 8px 15px !important"
                >
                  Score
                </Typography>

                <Box className="box-container score-container">
                  <Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography
                        variant="h6"
                        fontFamily="roboto"
                        fontSize="20px"
                        fontWeight="500"
                        color="#333333"
                        paddingBottom="15PX"
                      >
                        Your Badge Progression
                      </Typography>
                      <CircularProgress
                        score={
                          authen?.currentUser?.isAnonymous
                            ? 0
                            : profile.user?.metrics?.next_badge_words_scored
                        }
                        maxScore={
                          authen?.currentUser?.isAnonymous
                            ? 0
                            : profile.user?.metrics?.next_badge_words_total
                        }
                        total={
                          authen?.currentUser?.isAnonymous
                            ? '--'
                            : profile.user?.metrics?.next_badge_words_total
                        }
                      />
                      <Box sx={{ paddingTop: '30px', paddingBottom: '30px' }}>
                        <Typography
                          variant="h6"
                          fontFamily="roboto"
                          fontSize="20px"
                          fontWeight="500"
                          color="#333333"
                        >
                          Current Badge:{' '}
                          {authen.currentUser?.isAnonymous ? (
                            <Typography
                              component={'span'}
                              style={{ fontStyle: 'italic' }}
                            >
                              --
                            </Typography>
                          ) : (
                            <Typography
                              component={'span'}
                              variant="h6"
                              fontFamily="roboto"
                              fontSize="20px"
                              fontWeight="500"
                              color="#333333"
                              style={{ fontStyle: 'italic' }}
                            >
                              {profile?.user?.metrics?.name_badge_curr}
                            </Typography>
                          )}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontFamily="roboto"
                          fontSize="20px"
                          fontWeight="400"
                          color="#333333"
                        >
                          You're just{' '}
                          {authen?.currentUser?.isAnonymous
                            ? '--'
                            : profile.user?.metrics
                                ?.next_badge_words_remaining}{' '}
                          correct answers away from earning your next badge!
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      variant="h6"
                      fontFamily="roboto"
                      fontSize="20px"
                      fontWeight="500"
                      textAlign="center"
                      color="#2A9235"
                    >
                      Learning Progress Summary
                    </Typography>
                    <Box className="progress-box-parent">
                      <Box className="progress-box">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '9px',
                            paddingTop: '12px',
                          }}
                        >
                          <img
                            src={TotalAttempted}
                            alt="attempted-icon"
                            width="23%"
                          />
                          <Typography
                            variant="h6"
                            fontFamily="roboto"
                            fontSize="16px"
                            fontWeight="500"
                            color="#333333"
                            lineHeight="1"
                            className="total-score-font"
                          >
                            Total Attempted
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          fontFamily="roboto"
                          fontSize="30px"
                          fontWeight="600"
                          color="#4FC3F7"
                          textAlign="center"
                          marginTop="12px"
                          className="score-font-para"
                        >
                          {authen?.currentUser?.isAnonymous
                            ? '--'
                            : profile.user?.metrics?.words_num_total}
                        </Typography>
                      </Box>
                      <Box className="progress-box">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '9px',
                            paddingTop: '12px',
                          }}
                        >
                          <img
                            src={CheckedWords}
                            alt="attempted-icon"
                            width="20%"
                          />
                          <Typography
                            variant="h6"
                            fontFamily="roboto"
                            fontSize="16px"
                            fontWeight="500"
                            color="#333333"
                            lineHeight="1"
                            className="total-score-font"
                          >
                            Yay, Correct!
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          fontFamily="roboto"
                          fontSize="30px"
                          fontWeight="600"
                          color="#2A9235"
                          textAlign="center"
                          marginTop="12px"
                          className="score-font-para"
                        >
                          {authen?.currentUser?.isAnonymous
                            ? '--'
                            : profile.user?.metrics?.words_num_correct}
                        </Typography>
                      </Box>
                      <Box className="progress-box">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '9px',
                            paddingTop: '12px',
                          }}
                        >
                          <img
                            src={OopsWord}
                            alt="attempted-icon"
                            width="18%"
                          />
                          <Typography
                            variant="h6"
                            fontFamily="roboto"
                            fontSize="16px"
                            fontWeight="500"
                            color="#333333"
                            lineHeight="1"
                            className="total-score-font"
                          >
                            Oops, Incorrect
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          fontFamily="roboto"
                          fontSize="30px"
                          fontWeight="600"
                          color="#EC1C24"
                          textAlign="center"
                          marginTop="12px"
                          className="score-font-para"
                        >
                          {authen?.currentUser?.isAnonymous
                            ? '--'
                            : profile.user?.metrics?.words_num_incorrect}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </section>
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
              sx={{ pb: 3, alignItems: 'center' }}
              className="pt-0"
            >
              {/* <Box className="googleAds top " sx={{ textAlign: 'center' }}>
                <img src={googleads300} alt="Google Ads 300" />
              </Box> */}
            </Grid>
          </Grid>
        </Container>
      </main>
      <Footer />
    </>
  );
}
