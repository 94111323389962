import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import userimg from '../../../assets/img/user-group.svg';
import moonimg from '../../../assets/img/ri-moon.svg';
import solarimg from '../../../assets/img/solar-fire.svg';
import videoicon from '../../../assets/img/video-icon.svg';
import quotationicon from '../../../assets/img/quotation-icon.svg';
import testimonial1 from '../../../assets/img/testimonial1.png';
import testimonial2 from '../../../assets/img/testimonial2.png';
import testimonial3 from '../../../assets/img/testimonial3.png';
import testimonial4 from '../../../assets/img/testimonial4.png';
import testimonial5 from '../../../assets/img/testimonial5.png';
import testimonial6 from '../../../assets/img/testimonial6.png';
import { useInView } from 'react-intersection-observer';
import { Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../../scss/Landing.scss';
import { Box, Container, Grid, Typography, Button, Paper } from '@mui/material';
import { Helmet } from 'react-helmet';
export default function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    if (inView && !iframeLoaded) {
      setIframeLoaded(true);
    }
  }, [inView, iframeLoaded]);
  return (
    <>
      <Helmet>
        <title>
          Free Best Spelling Games Apps For Kids, Adults - Spelling Hero
        </title>
        <meta
          name="description"
          content="Spelling Hero offers 10,000+ words to practice, phonogram analysis, and areas for improvement. Tailored for kids and adults. 100% free and gamified fun!"
        />
        <link rel="canonical" href="https://spellinghero.app"></link>
      </Helmet>
      <header>
        <Navbar />
      </header>
      <main>
        <section>
          <Box
            sx={{
              py: 8,
              textAlign: 'center',
              backgroundColor: 'primary.main',
              color: 'white',
            }}
            className="header-banner"
          >
            <Container
              sx={{
                maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
              }}
            >
              <Box>
                <Typography variant="h3" component="h1" gutterBottom>
                  Test your spelling skills with over 10,000 words
                </Typography>
                <Button
                  sx={{ textTransform: 'none' }}
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="/home"
                  className="quiz-btn btn btn-primary"
                >
                  Start Quiz
                </Button>
              </Box>
            </Container>
          </Box>
        </section>
        <section>
          <Box sx={{ py: 8 }} className="features-item">
            <Container
              sx={{
                maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'flex-start' }}
                    className="spelling-success-img"
                  >
                    <img
                      src={userimg}
                      alt="Spelling success"
                      style={{ marginRight: '16px' }}
                    />
                    <Box>
                      <Typography
                        variant="h2"
                        className="font-600 spelling-senerio"
                      >
                        Spelling success
                      </Typography>
                      <Typography>
                        Enhance spelling skills and achieve mastery
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'flex-start' }}
                    className="spelling-success-img"
                  >
                    <img
                      src={moonimg}
                      alt="Improved spelling"
                      style={{ marginRight: '16px' }}
                    />
                    <Box>
                      <Typography
                        variant="h2"
                        className="font-600 spelling-senerio"
                      >
                        Improved spelling
                      </Typography>
                      <Typography>
                        Achieve accurate spelling and enhance language skills
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'flex-start' }}
                    className="spelling-success-img"
                  >
                    <img
                      src={solarimg}
                      alt="No more spelling stress"
                      style={{ marginRight: '16px' }}
                    />
                    <Box>
                      <Typography
                        variant="h2"
                        className="font-600 spelling-senerio"
                      >
                        No more spelling stress
                      </Typography>
                      <Typography>
                        Master spelling with ease and confidence
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <h3 className="item-heading features-landing"> Features</h3>
              <Box className=" item_listing">
                <Box className="item_box">
                  <Paper sx={{ p: 2 }} className="item-inner-box">
                    <Typography variant="h4">
                      Take test for difficulty levels
                    </Typography>
                    <Typography>
                      Test your spelling skills with varying difficulty levels
                    </Typography>
                  </Paper>
                </Box>
                <Box className="item_box">
                  <Paper sx={{ p: 2 }} className="item-inner-box">
                    <Typography variant="h4">
                      App offers personalized words
                    </Typography>
                    <Typography>
                      Practice spelling with words tailored to your skill level
                    </Typography>
                  </Paper>
                </Box>
                <Box className="item_box">
                  <Paper sx={{ p: 2 }} className="item-inner-box">
                    <Typography variant="h4">View history</Typography>
                    <Typography>
                      Track your progress and view your test history
                    </Typography>
                  </Paper>
                </Box>
                <Box className="item_box">
                  <Paper sx={{ p: 2 }} className="item-inner-box">
                    <Typography variant="h4">
                      Personalized phonogram report
                    </Typography>
                    <Typography>
                      Access a detailed phonogram report to enhance your
                      spelling skills
                    </Typography>
                  </Paper>
                </Box>
              </Box>

              <h3 className="item-heading pt-20"> Membership Levels</h3>
              <Box className=" item_listing">
                <Box className=" item-membership">
                  <Paper
                    className="item-inner-box"
                    sx={{ boxShadow: 'none !important' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginRight: '18px',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Box>
                        <Typography variant="h4" className="free-ads">
                          Free with ads
                        </Typography>
                        <Typography className="free-ads-subsection">
                          Enjoy the free membership level with ads displayed
                        </Typography>
                      </Box>
                      <Button
                        sx={{ mt: 2 }}
                        component={Link}
                        to="/signup"
                        className="btn-primary btn-member"
                      >
                        Sign up
                      </Button>
                    </Box>
                  </Paper>
                </Box>
                <Box className=" item-membership">
                  <Paper
                    className="item-inner-box"
                    sx={{ boxShadow: 'none !important' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginRight: '18px',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Box>
                        <Typography variant="h4" className="free-ads">
                          Paid - No Ads
                        </Typography>
                        <Typography className="free-ads-subsection">
                          Upgrade to the paid membership level without any ads
                        </Typography>
                      </Box>
                      <Button
                        sx={{ mt: 2 }}
                        component={Link}
                        to="/profile/3"
                        className="btn-member btn-primary"
                      >
                        Upgrade Membership
                      </Button>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Container>
          </Box>
        </section>
        <section>
          <Box sx={{ px: 8 }} className="video-tutorial">
            <Container
              sx={{
                maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ flex: 1, minWidth: '300px', mb: 2 }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    fontWeight="600 !important"
                    className="landing-subheading"
                  >
                    How to use Spelling Hero Website Tutorial Video
                  </Typography>
                  <Typography sx={{ mb: 2 }} className="landing-inner-heading">
                    Step-by-step guide for using the website
                  </Typography>
                  <Button
                    variant="contained"
                    className="btn btn-primary btn-member font-600"
                    startIcon={<img src={videoicon} alt="Watch now" />}
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/watch?v=IIID-HfcGN4',
                        '_blank'
                      )
                    }
                  >
                    Watch now
                  </Button>
                </Box>
                <Box sx={{ flex: 1, minWidth: '300px', mb: 2 }}>
                  <Box
                    sx={{ position: 'relative', paddingTop: '56.25%' }}
                    className="video-iframe"
                    ref={ref}
                  >
                    {iframeLoaded ? (
                      <iframe
                        src="https://www.youtube.com/embed/IIID-HfcGN4"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                        allowFullScreen
                      ></iframe>
                    ) : (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                      ></div>
                    )}
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        </section>
        <section>
          <Box className="client-testimonial">
            <Container
              sx={{
                maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
              }}
            >
              <Typography
                variant="h5"
                textAlign="center"
                gutterBottom
                className="join-para"
              >
                Join the Spelling Hero community and learn
              </Typography>
              <Swiper
                modules={[Pagination, Scrollbar]}
                spaceBetween={40}
                slidesPerView={3}
                scrollbar={{ draggable: true }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                breakpoints={{
                  300: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 3.5,
                  },
                }}
              >
                <SwiperSlide className="swiper-slide">
                  <Paper className="swiper-item">
                    <img src={quotationicon} alt="quote" className="quote" />
                    <Typography
                      variant="body2"
                      gutterBottom
                      fontSize="20px"
                      fontWeight="500"
                      lineHeight="1.6"
                    >
                      “I’ve seen great improvements in my students’ spelling
                      skills. Fantastic app!”
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={testimonial1}
                        alt="user"
                        className="user-icon"
                      />
                      <Box className="user-name">
                        <Typography variant="body1" className="name">
                          Mrs. Johnson.
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="destination"
                        >
                          Teacher, USA
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Paper className="swiper-item">
                    <img src={quotationicon} alt="quote" className="quote" />
                    <Typography
                      variant="body2"
                      gutterBottom
                      fontSize="20px"
                      fontWeight="500"
                      lineHeight="1.6"
                    >
                      “The app’s personalized recommendations make learning
                      effective for my students.”
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={testimonial2}
                        alt="user"
                        className="user-icon"
                      />
                      <Box className="user-name">
                        <Typography variant="body1" className="name">
                          Mr. Patel.
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="destination"
                        >
                          Teacher, India
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </SwiperSlide>

                <SwiperSlide className="swiper-slide">
                  <Paper className="swiper-item">
                    <img src={quotationicon} alt="quote" className="quote" />
                    <Typography
                      variant="body2"
                      fontSize="20px"
                      fontWeight="500"
                      lineHeight="1.6"
                      gutterBottom
                    >
                      “Phonogram analysis helps focus on areas needing
                      improvement.”
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={testimonial4}
                        alt="user"
                        className="user-icon"
                      />
                      <Box className="user-name">
                        <Typography variant="body1" className="name">
                          Ethan M.
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="destination"
                        >
                          Parent, USA
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Paper className="swiper-item">
                    <img src={quotationicon} alt="quote" className="quote" />
                    <Typography
                      variant="body2"
                      gutterBottom
                      fontSize="20px"
                      fontWeight="500"
                      lineHeight="1.6"
                    >
                      “Hearing words in multiple accents helps me attempt them
                      clearly!”
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={testimonial5}
                        alt="user"
                        className="user-icon"
                      />
                      <Box className="user-name">
                        <Typography variant="body1" className="name">
                          Amogh S.
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="destination"
                        >
                          Student, India
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Paper className="swiper-item">
                    <img src={quotationicon} alt="quote" className="quote" />
                    <Typography
                      variant="body2"
                      gutterBottom
                      fontSize="20px"
                      fontWeight="500"
                      lineHeight="1.6"
                    >
                      “The slow speed feature helps me understand sounds
                      clearly.”
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={testimonial3}
                        alt="user"
                        className="user-icon"
                      />
                      <Box className="user-name">
                        <Typography variant="body1" className="name">
                          Liam B.
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="destination"
                        >
                          Student, USA
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <Paper className="swiper-item">
                    <img src={quotationicon} alt="quote" className="quote" />
                    <Typography
                      variant="body2"
                      gutterBottom
                      fontSize="20px"
                      fontWeight="500"
                      lineHeight="1.6"
                    >
                      “I feel more confident and skilled.”
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={testimonial6}
                        alt="user"
                        className="user-icon"
                      />
                      <Box className="user-name">
                        <Typography variant="body1" className="name">
                          Kumar S.
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="destination"
                        >
                          Student, India
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </SwiperSlide>
              </Swiper>
            </Container>
          </Box>
        </section>
      </main>
      <Footer />
    </>
  );
}
