import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { onAuthStateChanged } from 'firebase/auth';
import { authen } from '../../firebase/firebase';
import deleteAccountApi from 'shared/Utils/DeleteUserApi/Api';
import { useNavigate } from 'react-router-dom';
import CustomDeleteModal from '../../components/CustomDeleteModal';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import RemoteErrorLog from '../../Utils/api';
import { Box } from '@mui/material';
import { environment } from 'apps/spellingheroweb/src/environments/environment';
import { isuserPremium } from '../../Utils/firebase-services';

const DeleteAccount = () => {
  const [user, setUser] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>();
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [
    isSubscriptionDeleteModalVisible,
    setIsSubscriptionDeleteModalVisible,
  ] = useState(false);
  const navigate = useNavigate();
  const hideModal = () => {
    setIsModalVisible(false);
    navigate('/home');
  };
  const hideNoQuestionModal = () => {
    setIsQuestionModalVisible(false);
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser); // Update the user state
      if (!currentUser) {
        localStorage.removeItem('userData');
      }
      if (currentUser == null) {
        localStorage.removeItem('userData');
      }
    });
    // Clean up the listener when the component unmounts
    handleDeleteClick();
    return () => unsubscribe();
  }, []);
  const handleDeleteClick = () => {
    setIsModalVisible(true);
  };

  const handleDeleteAccount = async () => {
    let value = await isuserPremium();
    if (value) {
      setIsModalVisible(false);
      setIsSubscriptionDeleteModalVisible(true);
    } else {
      deleteAccount();
    }
  };
  const hideSubscriptionDeleteModal = () => {
    setIsSubscriptionDeleteModalVisible(false);
  };
  const deleteAccount = async () => {
    const user = authen.currentUser;
    const decodedToken = await authen?.currentUser?.getIdTokenResult();

    if (user) {
      try {
        const userInfo = {
          uid: user?.uid,
          revCatSubscription: decodedToken?.claims?.revenueCatEntitlements
            ? true
            : false,
        };

        const token = await (authen?.currentUser as any).getIdToken(true);

        const result = await deleteAccountApi(
          userInfo,
          token,
          environment?.apiUrl
        );
        setIsModalVisible(false);

        if (result == 'Error') {
          setIsQuestionModalVisible(true);
          setApiErrorMessage(
            'We’re currently experiencing some issues. Please try again later.'
          );
          setIsModalVisible(false);
        } else if (!result?.error_message) {
          localStorage.removeItem('userData');
          navigate('/login');
        } else {
          setApiErrorMessage(result?.error_message);
          setIsQuestionModalVisible(true);
          setIsModalVisible(false);
        }
      } catch (error) {
        RemoteErrorLog(error, 'Navbar.tsx');
      }
    } else {
      window.alert('User not found.');
    }
  };
  return (
    <main>
      <Box>
        <Navbar />
        <CustomDeleteModal
          isModalVisible={isModalVisible}
          onPressbutton1={hideModal}
          onPressbutton2={handleDeleteAccount}
          buttonText1="No"
          buttonText2="Yes"
          modalTitle="Delete Account"
          text="This will delete all your data, including learning history. Once you delete your account, we cannot recover it. It will be gone forever."
          text2="Are you sure you want to continue?"
        />
        <CustomDeleteModal
          isModalVisible={isSubscriptionDeleteModalVisible}
          onPressbutton1={hideSubscriptionDeleteModal}
          onPressbutton2={deleteAccount}
          buttonText1="No"
          buttonText2="Yes"
          modalTitle="Delete Account"
          text="If you proceed with the deletion, we will cancel your purchased ad-free membership, and reactivation won’t be possible. Additionally, any remaining paid funds, valid until the end of the billing cycle, will be forfeited."
          text2="Are you sure you want to continue?"
        />
        <CustomNoResponseModal
          isModalVisible={isQuestionModalVisible}
          onPress={hideNoQuestionModal}
          buttonText="OK"
          modalTitle="Oops!"
          text={apiErrorMessage}
        />
      </Box>
    </main>
  );
};

export default DeleteAccount;
