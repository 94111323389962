import { cookieInfoType } from "../../types/globaltypes";
import axios from "axios";

const cookiePreferences = async (data: cookieInfoType,token:string,apiUrl:string) => {
    try {
      const response = await axios.post(
        `${apiUrl}createProfile`,
        data,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`
          },
          timeout:30000
        },
      );
      const apiData = response;
      return apiData.data;
    } catch (error) {
      return 'Error'
    }
  };

  export default cookiePreferences;