import { Reducer } from 'redux';
import { IBadgeState } from '../types/stateTypes';

// Define the Question type
export interface Badges {
  id: number;
  text: string;
}

const INITIAL_STATE: IBadgeState = {
  error: null,
  detail: null
}

const badgeReducer: Reducer<IBadgeState, any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_BADGES':
      return {...state, detail: action.payload };
    default:
      return state;
  }
};

export default badgeReducer;
