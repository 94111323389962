import { Reducer } from 'redux';

// Define the Question type
export interface Question {
  id: number;
  text: string;
}

const initialState: Question[] = [];
interface Action {
  type: string;
  payload: any; // Specify the actual type if possible
}
const questionReducer: Reducer<Question[], any> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_QUESTIONS':
      return action.payload;
    default:
      return state;
  }
};

export default questionReducer;
