import React from 'react';
import { Navigate } from 'react-router-dom';

const RequireAuthGuest = ({ children }: any) => {
  const userData = localStorage.getItem('userData') ?? '{}';
  const data = JSON.parse(userData);

  if (data?.uid == null) return <Navigate to="/login" />;
  return children;
};

export default RequireAuthGuest;
