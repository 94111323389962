import { uuidType } from "../../types/globaltypes";
import axios from "axios";

const verifyEmail = async (data: uuidType,apiUrl:string) => {
    try {
      const response = await axios.post(
         `${apiUrl}verifyEmail`,
        data,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          timeout:30000
        },
      );
      const apiData = response;
      return apiData;
    } catch (error) {
      return 'Error'
    }
  };
  export default verifyEmail;