import { Reducer } from 'redux';
import { IPagesState } from '../types/stateTypes';

// Define the Question type

const initialState: IPagesState = {
  error: null,
  detail: null
}
const staticPageReducer: Reducer<IPagesState, any> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAGES':
      return action.payload;
    default:
      return state;
  }
};

export default staticPageReducer;
