import axios from 'axios';
import { dateObjectType } from '../../types/globaltypes';

const getQuestionApi = async (questionInfo: dateObjectType , apiUrl:string) => {
  //const dispatch = useDispatch();
  try {
    const response = await axios.post(
      `${apiUrl}getWords`,    
      questionInfo,
      {
        method: 'POST',
        headers: {
          accept: 'application/json',
        },
        timeout:30000   
      },
    );
    const apiData = response;
    //dispatch(setQuestions(apiData.data));
    return apiData.data;
  } catch (error:any) {
      return 'Error'
  }
};
export default getQuestionApi;
