import { coppaEmailType } from '../../types/globaltypes';
import axios from 'axios';
const sendCoppaEmail = async (data: coppaEmailType,token:string, apiUrl:string) => {
  try {
    const response = await axios.post(
        `${apiUrl}sendEmail`,
      data,
      {
        method: 'POST',
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
        timeout:30000
      },
    );
    const apiData = response;
    return apiData.data;
  } catch (error) {
    return 'Error'
  }
};
export default sendCoppaEmail;