import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import quizimg from '../../../assets/img/quiz-img.png';
import { Link } from 'react-router-dom';
import './Sidebar.scss';

export default function Sidebar() {
  return (
    <Box component="aside" className="leftSidebar">
      <Box className="panelBox">
        <Typography variant="h4" component="h1" className="sidebar-title">
          IMPROVE YOUR SPELLING LEVEL
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Box component="img" src={quizimg} alt="" className="quiz-img" />
        </Box>
        <Box className="accountInfo">
          <Typography variant="h6">Create a free account to</Typography>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <span className="font-600">
                      {' '}
                      <span className="text-decoration-underline font-600 ">
                        Expand Your Vocabulary:
                      </span>{' '}
                      Access 10,000+ words to listen, type, and practice
                      spellings.
                    </span>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <span className="font-600">
                      {' '}
                      <span className="text-decoration-underline font-600">
                        Personalized Recommendations:
                      </span>{' '}
                      Tailored suggestions based on your history.
                    </span>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <span className="font-600">
                      {' '}
                      <span className="text-decoration-underline font-600">
                        Phonogram Insights:
                      </span>{' '}
                      Understand patterns and improve pronunciation.
                    </span>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <span className="font-600">
                      {' '}
                      <span className="text-decoration-underline font-600">
                        Troubled Words Tracker:
                      </span>{' '}
                      Monitor challenging words for targeted improvement.
                    </span>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <span className="font-600">
                      {' '}
                      <span className="text-decoration-underline font-600">
                        Track Your Progress:
                      </span>{' '}
                      Keep tabs on your learning journey and see how far you’ve
                      come!
                    </span>
                  </>
                }
              />
            </ListItem>
          </List>
          <Typography variant="h6">
            Join our community and embark on a rewarding spelling adventure!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
