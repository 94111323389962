import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import trashicon from '../../assets/img/error.png';

interface props {
  isModalVisible: boolean;
  onPressbutton1: any;
  onPressbutton2: any;

  buttonText1: string;
  buttonText2: string;

  text: string;

  modalTitle: string;
}

const CustomReportWordModal = ({
  isModalVisible,
  onPressbutton1,
  onPressbutton2,
  buttonText1,
  buttonText2,

  text,
  modalTitle,
}: props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog
      open={isModalVisible}
      onClose={onPressbutton1}
      aria-labelledby="email-confirm-dialog"
    >
      <DialogTitle id="email-confirm-dialog" style={{ textAlign: 'center' }}>
        {
          <img
            src={trashicon}
            className="mx-auto d-block mb-2"
            width={60}
            alt="Mail Icon"
          />
        }
        {modalTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center' }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onPressbutton1} color="primary" variant="contained">
          {buttonText1}
        </Button>
        <Button onClick={onPressbutton2} color="primary" variant="contained">
          {buttonText2}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomReportWordModal;
