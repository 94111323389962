import { Reducer } from 'redux';

// Define the Question type
export interface Avatar {
  id: number;
  text: string;
}

const initialState: Avatar[] = [];

const avatarReducer: Reducer<Avatar[], any> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AVATAR':
      return action.payload;
    default:
      return state;
  }
};

export default avatarReducer;
