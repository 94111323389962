import {createReducer} from '@reduxjs/toolkit';
import {AnyAction, Reducer} from 'redux';
import { IResultState } from '../types/stateTypes';

// Define the Question type


const INITIAL_STATE: IResultState = {
  error: null,
  detail: null,
};

const questionDetailReducer: Reducer<IResultState, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case 'SET_DETAILS':
      return {...state, detail: action['payload']};
    case 'RESET_DETAILS':
      return {...state, detail: action['payload']};
    default:
      return state;
  }
};

export default questionDetailReducer;
