import { createReducer } from '@reduxjs/toolkit';
import { AnyAction, Reducer } from 'redux';
import { IIFilterState } from '../types/stateTypes';

// Define the Question type


const INITIAL_STATE: IIFilterState = {
    error: null,
    filter: null
}

const filterReducer: Reducer<IIFilterState, any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_FILTER':
      return {...state, result: action['payload'] };
    default:
      return state;
  }
};

export default filterReducer;