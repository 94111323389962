export const isValidDate = (month: number, day: number, year: number) => {

    const date = new Date(year, month - 1, day);
    return (
      !isNaN(date.getDate()) &&
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  
  };

  export   const calculateAge = (
    selectedMonth: number,
    selectedDay: number,
    selectedYear: number,
  ) => {

    const today = new Date();
    const birthDate = new Date(selectedYear, selectedMonth - 1, selectedDay);

    const ageInMilliseconds = today.getTime() - birthDate.getTime();

    const ageInDays = ageInMilliseconds / (1000 * 60 * 60 * 24);
    const ageInYears = ageInDays / 365.25;
    return ageInYears;

    
  };