import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/Navbar/Navbar';
import { Box, CircularProgress } from '@mui/material';
import '../../scss/StaticPages.scss';
import Footer from '../../components/Footer/Footer';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../../firebase/firebase';
import { setStaticPages } from 'shared/store/src/lib/actions/staticPages';
import RemoteErrorLog from '../../Utils/api';
import { Helmet } from 'react-helmet';
const AboutUs = () => {
  const pagesUrl = useSelector((state: any) => state.pagesUrl);
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad: React.ReactEventHandler<HTMLIFrameElement> = () => {
    setIsLoading(false);
  };
  const dispatch = useDispatch();

  const fetchPages = async () => {
    if (!pagesUrl.length) {
      try {
        try {
          const storageRef = await ref(storage, 'pages');
          const result_1 = await listAll(storageRef);
          const urls = await Promise.all(
            result_1.items.map((pages) => getDownloadURL(pages))
          );
          dispatch(setStaticPages(urls));
        } catch (e) {
          RemoteErrorLog(e, 'AboutUs.tsx');
        }
      } finally {
      }
    }
  };
  useEffect(() => {
    fetchPages();
  }, []);
  return (
    <div
    // style={{
    //   background: 'linear-gradient(157.79deg, #DCF4DF 0%, #8DDF96 100%) ',
    // }}
    >
      <Helmet>
        <title>
          Spelling Practice Apps or Games For Grade 3rd,4th,5th,6th-About
        </title>
        <meta
          name="description"
          content="Spelling Hero is a spelling practice word app that provides different games for elementary students to improve their spelling skills in fun, exciting ways."
        />
        <link rel="canonical" href="https://spellinghero.app/about-us"></link>
      </Helmet>
      <header>
        <Navbar currentPage="aboutus" />
      </header>
      {/* <Header /> */}
      {isLoading && (
        <section>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
          >
            {' '}
            <CircularProgress
              size={56}
              sx={{
                color: 'primary.main',
              }}
            />{' '}
          </Box>
        </section>
      )}
      <section>
        <Box
          className="heading-static"
          style={{
            // position: 'relative',
            // left: 0,
            width: '100%',
            height: '80vh',
            background: 'linear-gradient(157.79deg, #DCF4DF 0%, #8DDF96 100%)',
            marginBottom: 0,
          }}
        >
          <Box className="about-heading">
            <h1
              style={{
                fontSize: 40,
                color: '#333',
                textAlign: 'center',
                letterSpacing: 5,
                fontFamily: 'roboto',
                paddingTop: '20px',
              }}
            >
              About Spelling Hero
            </h1>
          </Box>
          <iframe
            src={pagesUrl[0]}
            onLoad={handleLoad}
            title="Full Screen Iframe"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              minHeight: '100vh',
              margin: '0px 0px -7px 0px',
            }}
          />
          <Footer />
        </Box>
      </section>
    </div>
  );
};

export default AboutUs;
